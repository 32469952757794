import React from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';

import ImageFadeIn from 'components/Image';

import styles from './Fullimagewithcopy.module.scss';

export const Fullimagewithcopy = ({
  heading,
  subHeading,
  copy,
  image,
  ...props
}) => {
  const [width] = useWindowSize();
  const breakPoint = 768;

  return (
    <section className={classnames('wrap__max', styles.fullImageWithCopy)}>
      <div className={classnames('wrap__variant--four', styles.inner)}>
        <div className={styles.heading}>
          <h2
            className={'font-content-description'}
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h2>
        </div>
        <div className={styles.quote}>
          <h3
            className={'headings-font-display-8'}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          ></h3>
        </div>
        <div className={styles.image}>
          <div className={styles.inner}>
            {width >= breakPoint ? (
              <ImageFadeIn url={image.desktop.mediaItemUrl} />
            ) : (
              <ImageFadeIn url={image.mobile.mediaItemUrl} />
            )}
          </div>
        </div>
        <div
          className={classnames('font-base', styles.copy)}
          dangerouslySetInnerHTML={{ __html: copy }}
        ></div>
      </div>
    </section>
  );
};

export default Fullimagewithcopy;
