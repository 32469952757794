import React, { useState, useRef, useEffect, useContext } from 'react';
import { TimelineMax } from 'gsap';
import classnames from 'classnames';

import useWindowScroll from '@react-hook/window-scroll';
import { ThemeContext } from '../../theme/theme';

import styles from './Close.module.scss';

export const Close = React.memo(
  ({ isOpen, transparentHeader, className, ...props }) => {
    // const [isOpen, setOpen] = useState(false);
    const { closeButton, heroHeight } = useContext(ThemeContext);

    const [isScrolled, setScrolled] = useState(false);
    const [xTimeline] = useState(new TimelineMax({ paused: true }));

    const xOne = useRef();
    const xTwo = useRef();
    const xThree = useRef();
    const xFour = useRef();

    const scrollY = useWindowScroll(10);

    const scrollTriggerHeight = heroHeight > 0 ? heroHeight : 500;

    useEffect(() => {
      if (scrollY > scrollTriggerHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }, [scrollY, scrollTriggerHeight]);

    useEffect(() => {
      if (isOpen || closeButton) {
        xTimeline.play();
      } else {
        xTimeline.reverse();
      }
    }, [isOpen, closeButton, xTimeline]);

    useEffect(() => {
      xTimeline.to([xOne.current], 0.3, { x: '-5', y: '-5', rotation: 45 }, 0);
      xTimeline.to(
        [xThree.current],
        0.3,
        { x: '-5', y: '5', rotation: -45 },
        0,
      );
      xTimeline.to([xTwo.current], 0.3, { x: '5', y: '-5', rotation: -45 }, 0);
      xTimeline.to([xFour.current], 0.3, { x: '5', y: '5', rotation: 45 }, 0);
      xTimeline.to([xOne.current], 0.3, { x: '5', y: '1' }, 0.5);
      xTimeline.to([xThree.current], 0.3, { x: '5', y: '-1' }, 0.5);
      xTimeline.to([xTwo.current], 0.3, { x: '-2', y: '1' }, 0.5);
      xTimeline.to([xFour.current], 0.3, { x: '-2', y: '-1' }, 0.5);
    }, [xTimeline]);

    return (
      <div
        {...props}
        className={classnames(
          className,
          styles.hamburger,
          closeButton
            ? styles.hamburgerCloseButton
            : styles.hamburgerOpenButton,
          isScrolled ? styles.scrolled : null,
          {
            [styles.white]: transparentHeader === 'false',
          },
        )}
      >
        <div ref={xOne} className={classnames(styles.xOne, styles.x)} />
        <div ref={xTwo} className={classnames(styles.xTwo, styles.x)} />
        <div ref={xThree} className={classnames(styles.xThree, styles.x)} />
        <div ref={xFour} className={classnames(styles.xFour, styles.x)} />
      </div>
    );
  },
);

export default Close;
