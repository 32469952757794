import React, { useEffect } from "react";
import classnames from "classnames";

import { useTheme } from "theme";
import { ModalMinimal } from "components/Modal";
import Button from "components/Button";
import Register from "components/Register";
import Header from "components/Header";
import Footer from "components/Footer";

import styles from "./promotionlayout.module.scss";

export const PromotionLayout = ({ component: Component, ...props }) => {
  const { showLFSModal, toggleLFSModal } = useTheme();

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/" || path === "/home") toggleLFSModal();
  }, []); // eslint-disable-line

  return (
    <>
      <Header minimal />
      <Button type="register" className={styles.registerBtn} />
      <main className={classnames(styles.promotion, "blackbackground", "main")}>
        {Component ? <Component {...props} /> : props.children}
        <ModalMinimal isOpen={showLFSModal} toggle={toggleLFSModal}>
          <h3
            className="headings-font-display-3"
            style={{
              lineHeight: 1.4,
              fontSize: "1.3rem",
              marginBottom: "1rem"
            }}
          >
            Register to download the brochure & price list
          </h3>
          <Register className={styles.registerForm} howDoYouWishToBeContacted={false} />
        </ModalMinimal>
      </main>
      <Footer />
    </>
  );
};

const promotionLayout = Component => props => (
  <PromotionLayout {...props} component={Component} />
);

export default promotionLayout;
