import React from 'react';
import classnames from 'classnames';

import styles from './Heading.module.scss';

export const HeadingSmall = ({ bgcolor, color, heading }) => {
  return (
    <section
      className={classnames('wrap__max', styles.headingSmall)}
      style={{ backgroundColor: bgcolor }}
    >
      <div className={classnames(styles.inner, 'wrap__xxl')}>
        <h1 style={{color}} className={'headings-font-display-7'}>{heading}</h1>
      </div>
    </section>
  );
};

export default HeadingSmall;
