import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import { ThemeContext } from 'theme/theme';

import Icon from 'components/Icon';
import { ImageFadeIn } from 'components/Image';
import Wysiwyg from 'components/Wysiwyg';
import { Tag } from 'components/Tag';

import styles from './ArticleContent.module.scss';

export const ArticleContent = ({
  title,
  hero,
  excerpt,
  copy,
  themeSettings,
}) => {
  const [divHeight, setDivHeight] = useState();
  const [width] = useWindowSize();
  const { setNewsEventsBackgroundHeight } = useContext(ThemeContext);

  const { theme_settings } = themeSettings;

  useEffect(() => {
    setNewsEventsBackgroundHeight(divHeight);
  }, [width]);//eslint-disable-line
 
  return (
    <section className={styles.articleContent}>
      <div className={styles.socials}>
        <h3>Share</h3>
        <div className={styles.icons}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}
            className={classnames(styles.icon)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon fill="primary" name="fb" />
          </a>
          <a
            href={`mailto:${theme_settings.contactEmail}?subject=${title} | St Yves&body=${window.location}`}
            className={classnames(styles.icon)}
          >
            <Icon fill="primary" name="email" />
          </a>
        </div>
      </div>
      <div
        className={styles.introduction}
        ref={el => {
          if (!el) return;

          let prevValue = JSON.stringify(el.getBoundingClientRect());
          const handle = setInterval(() => {
            let nextValue = JSON.stringify(el.getBoundingClientRect());
            if (nextValue === prevValue) {
              clearInterval(handle);
              setDivHeight(el.getBoundingClientRect().height);
              setNewsEventsBackgroundHeight(el.getBoundingClientRect().height);
            } else {
              prevValue = nextValue;
            }
          }, 100);
        }}
      >
        <div className={styles.excerpt}>
          {/* <h3 dangerouslySetInnerHTML={{ __html: excerpt }}></h3> */}
        </div>
        <div className={styles.image}>
          <div className={styles.image__inner}>
            <ImageFadeIn url={hero} />
          </div>
        </div>
      </div>
      <div className={styles.wysiwyg}>
        <div className={styles.tag}>
          <Tag />
        </div>
        <Wysiwyg copy={copy} version={'article'} />
      </div>
    </section>
  );
};

export default ArticleContent;
