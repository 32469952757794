import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

import Navigation from "components/Navigation";
/**
 * GraphQL menu query
 * Gets the labels, types (internal or external) and URLs
 */

const HEADER_MENU_QUERY = gql`
  query MyQuery {
    headerMenu: menus(where: { slug: "header-menu" }) {
      nodes {
        menuItems {
          nodes {
            url
            label
            childItems {
              nodes {
                url
                label
              }
            }
          }
        }
      }
    }
    mobileMenu: menus(where: { slug: "mobile-menu" }) {
      nodes {
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  }
`;

export const Header = ({ minimal, ...props }) => {
  const [state, setState] = useState({
    headerMenu: [],
    mobileMenu: [],
  });

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executeMenu = async () => {
      const { client } = props;
      const headerMenuQuery = await client.query({
        query: HEADER_MENU_QUERY,
      });

      if (!requestCancelled) {
        const headerMenu =
          headerMenuQuery.data.headerMenu.nodes[0].menuItems.nodes;
        const mobileMenu =
          headerMenuQuery.data.mobileMenu.nodes[0].menuItems.nodes;
        setState({ headerMenu, mobileMenu });
      }
    };

    executeMenu();

    return () => {
      requestCancelled = true;
    };
  }, []); //eslint-disable-line

  const { headerMenu, mobileMenu } = state;

  return (
    <Navigation
      headerMenu={headerMenu}
      mobileMenu={mobileMenu}
      minimal={minimal}
    />
  );
};

export default withApollo(Header);
