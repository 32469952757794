import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import ImageFadeIn from 'components/Image';

import styles from './Listings.module.scss';

export const Listings = ({ listings, quote }) => {
  if (!listings) return null;
  return (
    <section className={classnames('wrap__max', styles.listings)}>
      <div className={classnames('wrap__xxl', styles.inner)}>
        {listings.edges.map((listing, i) => {
          
          return i !== listings.edges.length - 1 ? (
            <Link
              key={`blog-listing-${i}`}
              className={styles.individual}
              to={`/news-and-events/${listing.node.slug}`}
            >
              <div className={styles.image}>
                <div className={styles.imageHolder}>
                  {
                  listing.node.featuredImage && <ImageFadeIn url={listing.node.featuredImage.mediaItemUrl} />
                  }
                </div>
                <div className={styles.imageHover}></div>
              </div>
              <h2 className={classnames(styles.title)} dangerouslySetInnerHTML={{ __html: listing.node.title}} />
              {listing.node.excerpt ? (
                <div
                  className={styles.excerpt}
                  dangerouslySetInnerHTML={{
                    __html: `${listing.node.excerpt.slice(0, 100)}...`,
                  }}
                ></div>
              ) : null}
            </Link>
          ) : (
            <>
              <Link
                key={`blog-listing-${i}`}
                className={styles.individual}
                to={`/news-and-events/${listing.node.slug}`}
              >
                <div className={styles.image}>
                  <div className={styles.imageHolder}>
                    {
                      listing.node.featuredImage && <ImageFadeIn url={listing.node.featuredImage.mediaItemUrl} />
                    }
                  </div>
                  <div className={styles.imageHover}></div>
                </div>
                <h2 className={classnames(styles.title)}>
                  {listing.node.title}
                </h2>
                {listing.node.excerpt ? (
                  <div
                    className={styles.excerpt}
                    dangerouslySetInnerHTML={{
                      __html: `${listing.node.excerpt.slice(0, 100)}...`,
                    }}
                  ></div>
                ) : null}
              </Link>
              {quote ? (
                <div
                  className={classnames(styles.individual, styles.featureQuote)}
                >
                  <h3 dangerouslySetInnerHTML={{ __html: quote }}></h3>
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    </section>
  );
};

export default Listings;
