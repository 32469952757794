import React, { useEffect, useState, useContext } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import Seo from "components/Seo";
import { ThemeContext } from "theme/theme";
import { toComponentName } from "../../utils/toComponentName";
import * as blocks from "./blocks";
import * as layouts from "layouts";

/**
 * GraphQL page query that takes a page slug as a uri
 * Returns the title and content of the page
 */

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      pageWrapperClass {
        classname
      }
      pageId
      slug
      title
      content
      uri
      seo {
        metaDesc
        title
      }
      template {
        ... on DefaultTemplate {
          templateName
        }
        ... on PromotionTemplateTemplate {
          templateName
        }
      }
      acf_layout {
        content {
          ... on Page_AcfLayout_Content_Quote {
            fieldGroupName
            title
            quote
          }

          ... on Page_AcfLayout_Content_PropertyApp {
            fieldGroupName
            packages
          }

          ... on Page_AcfLayout_Content_Copy {
            copyBackgroundColour
            copyFeatureText
            copyHeading
            copyWysiwyg
            fieldGroupName
            button {
              type
              label
              colour
            }
          }

          ... on Page_AcfLayout_Content_Introduction {
            copy
            featureText
            heading
            imageOne {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
            imageTwo {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
            fieldGroupName
          }

          ... on Page_AcfLayout_Content_DiscoverRoseworthy {
            fieldGroupName
            discoverBlockTitle
            discoverDescription
            discoverHeading
            discoverImageOne {
              mediaItemUrl
            }
            discoverImageTwo {
              mediaItemUrl
            }
          }

          ... on Page_AcfLayout_Content_Gallery {
            fieldGroupName
            galleryQuote
            galleryImages {
              eight {
                mediaItemUrl
              }
              five {
                mediaItemUrl
              }
              four {
                mediaItemUrl
              }
              one {
                mediaItemUrl
              }
              seven {
                mediaItemUrl
              }
              six {
                mediaItemUrl
              }
              three {
                mediaItemUrl
              }
              two {
                mediaItemUrl
              }
            }
            points {
              five
              four
              one
              three
              two
            }
          }

          ... on Page_AcfLayout_Content_FeatureSlider {
            description
            fieldGroupName
            heading
            staticContent
            subHeading
            showButton
            images {
              heading
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
              subHeading
            }
            button {
              colour
              emailAddress
              fieldGroupName
              label
              link {
                url
              }
              phoneNumber
              type
            }
          }

          ... on Page_AcfLayout_Content_FullImageWithCopy {
            copy
            fieldGroupName
            heading
            subHeading
            image {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
          }
          ... on Page_AcfLayout_Content_FullImageWithLink {
            fieldGroupName
            heading
            button {
              colour
              emailAddress
              fieldGroupName
              label
              phoneNumber
              type
              link {
                url
              }
            }
            images {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
          }
          ... on Page_AcfLayout_Content_ImageTextWithWatermark {
            buttonLeaderText
            copy
            heading
            fieldGroupName
            button {
              colour
              emailAddress
              fieldGroupName
              label
              phoneNumber
              type
              link {
                url
              }
            }
            image {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
          }

          ... on Page_AcfLayout_Content_Education {
            copy
            fieldGroupName
            heading
            featureText
            blockquote
            logo {
              mediaItemUrl
            }
            buttonOne {
              colour
              emailAddress
              fieldGroupName
              label
              link {
                url
              }
              telephoneNumber
              type
            }
            buttonTwo {
              colour
              emailAddress
              fieldGroupName
              label
              phoneNumber
              type
              link {
                url
              }
            }
            subHeading
            imageOne {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
              text
            }
            imageTwo {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
              text
            }
          }
          ... on Page_AcfLayout_Content_TwoColumnTextImage {
            fieldGroupName
            tagText
            title
            headline
            details
            image {
              mediaItemUrl
            }
            overlayText
          }
          ... on Page_AcfLayout_Content_TwoColumnImageLogoText {
            fieldGroupName
            logo {
              mediaItemUrl
            }
            details
            image {
              mediaItemUrl
            }
            overlayText
          }
          ... on Page_AcfLayout_Content_HeroSlider {
            fieldGroupName
            styling
            centreText
            heading
            subHeading
            showForm
            form {
              headline
            }
            showButton
            button {
              colour
              emailAddress
              fieldGroupName
              label
              phoneNumber
              type
              link {
                url
              }
            }
            showButtonSecondary
            buttonSecondary {
              colour
              emailAddress
              fieldGroupName
              label
              phoneNumber
              type
              link {
                url
              }
            }
            images {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
            enableVideo
            videoBackground {
              mediaItemUrl
            }
            videoBackgroundMobile {
              mediaItemUrl
            }
            stamp {
              mediaItemUrl
            }
            stampLink {
              url
            }
          }
          ... on Page_AcfLayout_Content_MinimalHero {
            fieldGroupName
            title
            background {
              mediaItemUrl
            }
          }
          ... on Page_AcfLayout_Content_Builders {
            fieldGroupName
            heading
            introduction
            address
            addressLink
            openingHours
            downloadButton
            downloadButtonLink
            builderIndividual {
              builderName
              builderLogo {
                mediaItemUrl
              }
              builderLink {
                ... on Page {
                  uri
                  link
                }
              }
              nowOpen
            }
          }
          ... on Page_AcfLayout_Content_DisplayHomes {
            fieldGroupName
            title
            homes {
              title
              image {
                mediaItemUrl
              }
              address
              bed
              bath
              car
              buttonLabel
              buttonLink
            }
          }
          ... on Page_AcfLayout_Content_ImageCta {
            fieldGroupName
            title
            subtitle
            buttonLabel
            buttonLink
            image {
              mediaItemUrl
            }
          }
          ... on Page_AcfLayout_Content_BuilderBanner {
            fieldGroupName
            paragraph
            builderLogo {
              mediaItemUrl
            }
          }
          ... on Page_AcfLayout_Content_BuilderInfo {
            fieldGroupName
            paragraph
            address
            directionsLink
            openingHours
          }
          ... on Page_AcfLayout_Content_Cta {
            fieldGroupName
            title
            paragraph
            buttonLabel
            buttonLink
          }
          ... on Page_AcfLayout_Content_ContactDetails {
            fieldGroupName
            title
            headline
            company {
              name
              logo {
                mediaItemUrl
              }
              phone
              email
            }
          }
          ... on Page_AcfLayout_Content_MapBlock {
            fieldGroupName
            desktopMap {
              mediaItemUrl
            }
            mobileMap {
              mediaItemUrl
            }
          }
          ... on Page_AcfLayout_Content_AboutUs {
            aboutFeatureText
            aboutHeading
            aboutSubHeading
            fieldGroupName
            aboutLogo {
              mediaItemUrl
            }
            aboutImage {
              desktop {
                mediaItemUrl
              }
              mobile {
                mediaItemUrl
              }
            }
          }

          ... on Page_AcfLayout_Content_FeatureNewsAndEvents {
            articleOne {
              ... on Post {
                id
                title
                slug
                date
                featuredImage {
                  mediaItemUrl
                }
              }
            }
            articleTwo {
              ... on Post {
                id
                title
                slug
                date
                featuredImage {
                  mediaItemUrl
                }
              }
            }
            fieldGroupName
          }
        }
        fieldGroupName
      }
    }
    posts {
      edges {
        node {
          id
          slug
          title
          postId
          date
          categories {
            nodes {
              name
              slug
              categoryId
            }
          }
          excerpt
          featuredImage {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

/**
 * Fetch and display a Page
 */
export const Page = ({ ...props }) => {
  const [state, setState] = useState({
    page: {
      title: "",
      content: "",
      pageId: null,
      uri: "",
      seo: "",
      acf_layout: {
        content: null
      },
      template: {
        templateName: null
      }
    },
    post: null
  });

  const { setTransparentHeader, setWhiteHeader } = useContext(ThemeContext);

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePageQuery = async () => {
      const { match, client } = props;
      let uri = match.url.substr(1);

      if (!uri) {
        uri = "home";
      }

      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri }
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        const posts = result.data.posts;
        setState({ page, posts });
      }
    };

    // Execute page query, process the response and set the state
    executePageQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props.match]); // eslint-disable-line

  const { page, posts } = state;
  const acf_blocks = page.acf_layout.content;
  const template = (page.template.templateName || "")
    .replace(/ /g, "")
    .replace("Template", "Layout");
  const EmptyLayout = ({ children }) => (
    <main className="main">{children}</main>
  );
  const Layout = layouts[template] || EmptyLayout;

  useEffect(() => {
    if (page.uri === "/" || "/home" || "/display-village/*/") {
      setTransparentHeader();
    }
    if (page.uri === "/display-village/") {
      setWhiteHeader();
    }
  }, [page.uri]); // eslint-disable-line

  const wrapperClassName =
    page.pageWrapperClass && page.pageWrapperClass.classname;
  return (
    <Layout pageSlug={wrapperClassName}>
      <div className={`mainContent wrap__hundred post-${page.pageId}`}>
        <Seo title={page.title} yoast={page.seo} />
        {acf_blocks &&
          acf_blocks.map((block, i) => {
            const name = toComponentName(block.fieldGroupName);
            const Component = blocks[name];
            return (
              <div key={i} className="wrap__hundred">
                <Component {...block} posts={posts} />
              </div>
            );
          })}
      </div>
    </Layout>
  );
};

export default withApollo(Page);
