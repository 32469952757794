import React, { useState, useEffect, useRef, useCallback } from "react";
import classnames from "classnames";
import { Power3, TweenLite, Draggable, gsap } from "gsap/all";
import { useWindowWidth } from "@react-hook/window-size";
import { polygon, center } from "@turf/turf";
import { ReactComponent as SVGKey } from "./img/key.svg";

import Button from "components/Button";
//import { ReactComponent as Reset } from './img/reset.svg';

import styles from "./MarkersMap.module.scss";

function getMin(data, attr) {
  return (
    data.reduce((min, p) => (p[attr] < min ? p[attr] : min), data[0][attr]) * 1
  );
}
function getMax(data, attr) {
  return (
    data.reduce((max, p) => (p[attr] > max ? p[attr] : max), data[0][attr]) * 1
  );
}
const getMinimumXY = (data) => [getMin(data, "x"), getMin(data, "y")];
const getMaximumXY = (data) => [getMax(data, "x"), getMax(data, "y")];
const percentageToPx = (percentage, totalPx) => {
  return Math.trunc((percentage / 100) * totalPx);
};

const INITIAL_SCALE = 0.8;
const DEFAULT_MARKERS_SCALE = 1.2;

gsap.registerPlugin(Draggable);

export const MarkersMap = ({
  className,
  levels,
  onMarkerClick,
  activeMarker,
  zoom,
  bgcolor,
  download,
  legend,
}) => {
  const screenWidth = useWindowWidth();
  const [firstLevel] = levels || [];
  const [mapHeight, setMapHeight] = useState(400);
  const [markersScale, setMarkersScale] = useState(1.2);
  const [resettedZoom, setResettedZoom] = useState(false); //eslint-disable-line
  const [selected, setSelected] = useState(firstLevel || {});
  const { markers } = selected;
  const [showLegend, setLegend] = useState(false);

  const map = useRef(null);
  const wrapper = useRef(null);
  const draggable = useRef(null);
  const breakPoint = 768;

  // console.log(levels);

  const centerActiveMarker = useCallback(
    (options) => {
      const { zoom: scaleZoom } = options || {};
      const isMobile = screenWidth <= 450;
      const marker = activeMarker;
      const [firstCoordinate, secondCoordinate] = marker.coordinates;

      const isTwoMarkers = marker.coordinates.length === 2;
      const isMultipleMarkers = marker.coordinates.length >= 3;

      const poly = isMultipleMarkers
        ? polygon([
            [
              ...marker.coordinates.map(({ x, y }) => [x * 1, y * 1]),
              [firstCoordinate.x * 1, firstCoordinate.y * 1],
            ],
          ])
        : null;

      const centerPoint = poly ? center(poly) : null;

      const [centerX, centerY] = centerPoint
        ? centerPoint.geometry.coordinates
        : isTwoMarkers
        ? [
            Math.abs(
              (parseInt(firstCoordinate.x) + parseInt(secondCoordinate.x)) / 2
            ),
            Math.abs(
              (parseInt(firstCoordinate.y) + parseInt(secondCoordinate.y)) / 2
            ),
          ]
        : [];

      const [minX, minY] = getMinimumXY(marker.coordinates);
      const [maxX, maxY] = getMaximumXY(marker.coordinates);

      const markerXPercentage =
        isMultipleMarkers || isTwoMarkers ? centerX - 1 : parseInt(maxX);
      const markerYPercentage =
        isMultipleMarkers || isTwoMarkers ? centerY : parseInt(maxY);

      // =================
      // X Coordinate
      // =================

      // get the current position of the image on the wrapper (transform: xx)
      const currentX = Math.trunc(Math.abs(draggable.current.x));
      // console.log('currentX: ',currentX)
      // get the wrapper width (parent div)
      const wrapperWidth =
        draggable.current.target.parentNode.parentNode.clientWidth;
      // console.log('wrapperWidth: ',wrapperWidth)
      // calculate the middle point of the wrapper
      const xMiddlePoint = currentX + wrapperWidth / 2;
      // console.log('xMiddlePoint: ',xMiddlePoint)
      // width of the map image
      // console.log('wrapperWidth: ', wrapperWidth);
      // console.log('xMiddlePoint:', xMiddlePoint)
      const width = draggable.current.target.clientWidth;
      // calculate the marker position base on the map image
      const markerX = percentageToPx(markerXPercentage, width);
      // console.log('markerX: ',markerX)
      // get the distance from the marker point to the middle point
      let distanceX = Math.abs(markerX - xMiddlePoint);
      // let distanceX = markerX - xMiddlePoint;
      // console.log('distanceX: ', markerX , xMiddlePoint, distanceX)
      if (isMultipleMarkers) distanceX -= 50;

      // console.log('distanceX: ', distanceX)

      // get the direction of travel by checking if the marker is on the
      // left side or the right side of the middle point (marker > xMiddlePoint)
      const directionX =
        distanceX === 0 ? "none" : markerX > xMiddlePoint ? "left" : "right";

      // console.log("currentX", currentX);
      // console.log("markerX", markerX);
      // console.log("wrapperWidth", wrapperWidth);
      // console.log("xMiddlePoint", xMiddlePoint);
      // console.log("distanceX", distanceX);
      // console.log("directionX", directionX);
      // console.log("=======================");

      const x = directionX === "left" ? `-=${distanceX}` : `+=${distanceX}`;

      // console.log(directionX, x)

      // console.log('x: ', x)

      // =================
      // Y Coordinate
      // =================

      // get the current position of the image on the wrapper (transform: xx)
      const currentY = Math.trunc(Math.abs(draggable.current.y));
      // get the wrapper width (parent div)
      const wrapperHeight =
        draggable.current.target.parentNode.parentNode.clientHeight;
      // calculate the middle point of the wrapper
      const yMiddlePoint = currentY + wrapperHeight / 2;

      // width of the map image
      const height = draggable.current.target.clientHeight;
      // calculate the marker position base on the map image
      const markerY = percentageToPx(markerYPercentage, height);
      // get the distance from the marker point to the middle point
      let distanceY = Math.abs(markerY - yMiddlePoint);
      if (isMobile && isMultipleMarkers) distanceY += 20;
      // get the direction of travel by checking if the marker is on the
      // left side or the right side of the middle point (marker > yMiddlePoint)
      const directionY =
        distanceY === 0 ? "none" : markerY > yMiddlePoint ? "top" : "bottom";

      const y = directionY === "top" ? `-=${distanceY}` : `+=${distanceY}`;

      // console.log("markerY", markerY);
      // console.log("currentY", currentY);
      // console.log("wrapperHeight", wrapperHeight);
      // console.log("yMiddlePoint", yMiddlePoint);
      // console.log("distanceY", distanceY);
      // console.log("directionY", directionY);
      // console.log("=======================");
      //
      // ================
      // Scale (Zoom)
      // ================
      const totalWidthToShow =
        percentageToPx(maxX, width) - percentageToPx(minX, width);
      let xScale = isMultipleMarkers
        ? wrapperWidth / totalWidthToShow
        : INITIAL_SCALE;
      // max scale should be 0.4
      if (xScale < 0.45) xScale = 0.45;

      const totalHeightToShow =
        percentageToPx(maxY, height) - percentageToPx(minY, height);
      let yScale = isMultipleMarkers
        ? wrapperHeight / totalHeightToShow
        : INITIAL_SCALE;
      // max scale should be 0.4
      if (yScale < 0.45) yScale = 0.45;

      // console.log("++++++++++++++++++++++++++++++++++++++");
      // console.log(
      //   "totalWidthToShow, totalHeightToShow",
      //   totalWidthToShow,
      //   totalHeightToShow
      // );
      // console.log("wrapperWidth, wrapperHeight", wrapperWidth, wrapperHeight);
      // console.log("width, height", width, height);
      // console.log("xScale, yScale", xScale, yScale);
      setTimeout(() => {
        TweenLite.to(map.current, 0.8, {
          x,
          y,
          // snap: {
          //   x: value => parseInt(value),
          //   y: value => parseInt(value)
          // },
          scale: scaleZoom
            ? scaleZoom
            : isMultipleMarkers
            ? Math.min(xScale, yScale) - 0.1
            : INITIAL_SCALE,
          // isMobile ? yScale : [xScale, yScale],
          ease: Power3.easeInOut,
          onUpdate: () => {
            draggable.current.update(true);
            //map.current.style.zIndex = 9;
          },
        });
      }, 200);

      if (scaleZoom) {
        setMarkersScale(scaleZoom + DEFAULT_MARKERS_SCALE);
        setResettedZoom(true);
      } else {
        setMarkersScale(
          isMultipleMarkers
            ? Math.min(xScale, yScale) - 0.1 + INITIAL_SCALE
            : DEFAULT_MARKERS_SCALE
        );
        setResettedZoom(false);
      }

      // .to(map.current, 0.8, {
      //   scale: xScale // isMobile ? yScale : [xScale, yScale],
      // });

      // const timeline = new TimelineMax();
      //
      // timeline
      //   .to(map.current, 0.8, {
      //     x,
      //     y,
      //     scale: 1,
      //     ease: Power3.easeInOut,
      //     onUpdate: () => draggable.current.update(true)
      //   })
      //   .to(
      //     map.current,
      //     0.8,
      //     {
      //       scale: 1.3,
      //       ease: Power3.easeIn
      //       // onUpdate: () => draggable.current.update(true)
      //     },
      //     0.5
      //   );

      return () => {};
    },
    [activeMarker, screenWidth]
  );
  useEffect(() => setSelected(firstLevel), [firstLevel]);
  useEffect(() => {
    if (zoom && map.current) {
      const setIndex = () => {
        map.current.style.zIndex = 9;
      };

      draggable.current = new Draggable(map.current, {
        type: "x,y",
        bounds: wrapper.current,
        onPress: setIndex,
        onDrag: setIndex,
        liveSnap: true,
        snap: {
          x: (value) => parseInt(value),
          y: (value) => parseInt(value),
        },
      });
    }
    setMapHeight(map.current.offsetHeight);
  }, [zoom, selected, map]);

  useEffect(() => {
    setMapHeight(map.current.offsetHeight);
  }, []);

  useEffect(() => {
    if (zoom) setTimeout(() => centerActiveMarker(), 10);
  }, [zoom, activeMarker, activeMarker.id, centerActiveMarker, screenWidth]);

  const resetZoom = () => centerActiveMarker({ zoom: 0.5 }); //eslint-disable-line

  // if (!selected.colour) selected.colour = "black";

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.static]: !zoom,
      })}
      style={{ backgroundColor: bgcolor, minHeight: `${mapHeight}px` }}
    >
      <div ref={wrapper} className={styles.wrapper}>
        {legend && (
          <>
            <div
              className={classnames(styles.legendToggle, {
                [styles.active]: showLegend,
              })}
              onClick={() => {
                setLegend(!showLegend);
              }}
            >
              Key
            </div>
            <SVGKey
              className={classnames(styles.legend, {
                [styles.active]: showLegend,
              })}
            />
          </>
        )}
        {/* {!resettedZoom ? (
          <button className={styles.reset} onClick={resetZoom}>
            <Reset className={classnames(styles.reseticon)} />
          </button>
        ) : null} */}
        {download && (
          <Button
            color="primary"
            text="Download Masterplan"
            type="external"
            link={download}
            size="small"
            className={styles.download}
          />
        )}
        <div
          ref={map}
          className={classnames(styles.map, { [styles.static]: !zoom })}
        >
          <img src={selected.image} alt={selected.name} />
          <div className={styles.markers}>
            <style>
              {`.markers-active-colour:after { border-color: ${selected.colour}; }`}
            </style>

            {markers.map((marker, i) => {
              if (!marker) return null;
              if (!marker.coordinates) return null;
              if (!marker.coordinates.length) return null;

              const active = activeMarker.id === marker.id;

              return marker.coordinates.map((coordinates, i2) => (
                <button
                  key={`markers-${marker.id || i}-${i2}`}
                  className={classnames(
                    { [styles.active]: active, "marker-active": active },
                    "markers-active-colour",
                    styles.marker
                  )}
                  style={
                    !coordinates.mobileAdjustments
                      ? {
                          left: `${coordinates.x}%`,
                          top: `${coordinates.y}%`,
                          backgroundColor: selected.colour,
                          transform: `scale(${markersScale})`,
                        }
                      : coordinates.mobileAdjustments &&
                        screenWidth > breakPoint
                      ? {
                          left: `${coordinates.x}%`,
                          top: `${coordinates.y}%`,
                          backgroundColor: selected.colour,
                          transform: `scale(${markersScale})`,
                        }
                      : coordinates.mobileAdjustments &&
                        screenWidth <= breakPoint
                      ? {
                          left: `${coordinates.xMobile}%`,
                          top: `${coordinates.yMobile}%`,
                          backgroundColor: selected.colour,
                          transform: `scale(${markersScale})`,
                        }
                      : null
                  }
                  onClick={() => onMarkerClick(marker)}
                >
                  {i + 1}
                  {console.log(
                    "co oords",
                    coordinates,
                    screenWidth,
                    breakPoint
                  )}
                </button>
              ));
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkersMap;
