import React, { useRef, useEffect } from "react";
import { PropertyAppProvider } from "react-property-app";
import ReactDOM from "react-dom";
import classnames from "classnames";
import PropertyApp from "react-property-app";
import config from "react-property-app/config";
import { Helmet } from "react-helmet";
import { HeadingNowSelling } from "components/Heading";
import { useTheme } from "theme";
import styles from "./LandForSale.module.scss";
require("./targeted.css");

// const useReactPath = () => {
//   const [path, setPath] = React.useState(window.location.pathname);
//   const listenToPopstate = () => {
//     const winPath = window.location.pathname;
//     setPath(winPath);
//   };
//   React.useEffect(() => {
//     window.addEventListener("popstate", listenToPopstate);
//     return () => {
//       window.removeEventListener("popstate", listenToPopstate);
//     };
//   }, []);
//   return path;
// };

const DownloadPriceButton = () => {
  const { forceToggleLFSModal } = useTheme();
  const [downloadContainer] = document.getElementsByClassName("lfs--downloads");

  if (downloadContainer) {
    const onClick = (e) => {
      e.preventDefault();
      forceToggleLFSModal();
    };

    return ReactDOM.createPortal(
      <a
        href="/#"
        className="ta-btn ta-btn__small ta-btn__secondary"
        onClick={onClick}
      >
        <span
          className="ta-icon"
          style={{
            backgroundImage: `url('${config.style.get("icons.download")}')`
          }}
        />
        Download Price list
      </a>,
      downloadContainer
    );
  }

  return null;
};

export const LandForSale = ({ location }) => {
  // const path = useReactPath();
  const showedModal = useRef();
  const { toggleLFSModal } = useTheme();
  const { transparentHeader, setWhiteHeader } = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setWhiteHeader();
    }, 1000);
  }, [transparentHeader]); // eslint-disable-line

  useEffect(() => {
    const paths = location.pathname.split("/").filter((p) => p);

    if (paths.length === 2 && !showedModal.current) {
      showedModal.current = true;
      toggleLFSModal();
    }
  }, [location.pathname, toggleLFSModal]);

  useEffect(() => {
    setWhiteHeader();
  }, [window.location.pathname]); // eslint-disable-line

  // useEffect(() => {

  //   //mvp sold out code start
  //   const navTitle = document.getElementsByClassName("lfs--nav");
  //   const title =
  //     navTitle &&
  //     navTitle.length > 0 &&
  //     [].slice
  //       .call([].slice.call(navTitle)[0].childNodes)
  //       .filter((node) => node.nodeName === "H2")[0];
  //   const titleText = title && title.childNodes[0].data;

  //   if (titleText === "Avenue Release") {
  //     title.innerHTML += ' <span style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (titleText === "High Street Release") {
  //     title.innerHTML += ' <span style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (titleText === "Monfort Release") {
  //     title.innerHTML += ' <span style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (titleText === "Esquire Release") {
  //     title.innerHTML += ' <span style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (titleText === "Marquis Release") {
  //     title.innerHTML += ' <span style="color: #ba0d2f;">(Sold Out)</span>';
  //   }

  //   const navData = document.getElementsByClassName("lfs--nav--link");
  //   const navItem =
  //     navData &&
  //     [].slice
  //       .call(navData)
  //       .filter((item) => item.childNodes[0].data === "Avenue Release")[0];
  //   const navItemTwo =
  //     navData &&
  //     [].slice
  //       .call(navData)
  //       .filter((item) => item.childNodes[0].data === "High Street Release")[0];
  //   const navItemThree =
  //     navData &&
  //     [].slice
  //       .call(navData)
  //       .filter((item) => item.childNodes[0].data === "Monfort Release")[0];
  //   const navItemFour =
  //     navData &&
  //     [].slice
  //       .call(navData)
  //       .filter((item) => item.childNodes[0].data === "Esquire Release")[0];
  //   const navItemFive =
  //     navData &&
  //     [].slice
  //       .call(navData)
  //       .filter((item) => item.childNodes[0].data === "Marquis Release")[0];

  //   if (navItem) {
  //     navItem.innerHTML +=
  //       ' <span class="lots-available sold" style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (navItemTwo) {
  //     navItemTwo.innerHTML +=
  //       ' <span class="lots-available sold" style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (navItemThree) {
  //     navItemThree.innerHTML +=
  //       ' <span class="lots-available sold" style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (navItemFour) {
  //     navItemFour.innerHTML +=
  //       ' <span class="lots-available sold" style="color: #ba0d2f;">(Sold Out)</span>';
  //   }
  //   if (navItemFive) {
  //     navItemFive.innerHTML +=
  //       ' <span class="lots-available sold" style="color: #ba0d2f;">(Sold Out)</span>';
  //   }

  //   var i, run, li, stop;
  //   const lists = document.querySelector(".lfs--nav--scroll ul");

  //   run = true;
  //   if (lists) {
  //     while (run) {
  //       run = false;
  //       li = lists.getElementsByTagName("LI");
  //       for (i = 0; i < li.length - 1; i++) {
  //         stop = false;
  //         if (
  //           li[i].innerHTML.includes("sold") &&
  //           !li[i + 1].innerHTML.includes("sold")
  //         ) {
  //           stop = true;
  //           break;
  //         }
  //       }
  //       if (stop) {
  //         li[i].parentNode.insertBefore(li[i + 1], li[i]);
  //         run = true;
  //       }
  //     }
  //   }
  //   //mvp sold out code end
  // }, []);


  // useEffect(() => {
  //   const navTitle =  document.querySelector(".lfs--nav .package--subtitle__type"); 
  //   if(navTitle && navTitle.innerHTML === "Reserve Release") {
  //     const packageTitle =  document.querySelector(".package--title h3 span + span + span");
  //     packageTitle.innerHTML = "Contact Agent";
  //   }
  // }, [path]);

  const paths = location.pathname.split("/").filter((p) => p);
  const showDownloadButton = paths.length === 1 || paths.length === 2;

  return (
    <PropertyAppProvider>
      <section className={classnames("wrap__max", styles.wrapper)}>
        <Helmet>
          <title itemProp="name" lang="en">
            St Yves | Land for Sale
          </title>
        </Helmet>
        <HeadingNowSelling title="Land for Sale" className={styles.heading} />
        <PropertyApp />
        {showDownloadButton ? <DownloadPriceButton /> : null}
      </section>
    </PropertyAppProvider>
  );
};

export default LandForSale;
