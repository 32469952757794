import React from 'react';
import classnames from 'classnames';
import Close from 'components/Close';

import styles from './ModalMinimal.module.scss';

export const ModalMinimal = ({ isOpen, toggle, children }) => (
  <div className={classnames(styles.modal, { [styles.open]: isOpen })}>
    <Close className={styles.closeButton} onClick={toggle} isOpen />
    <div className={styles.content}>{children}</div>
    <div onClick={toggle} className={styles.backdrop} />
  </div>
);

export default ModalMinimal;
