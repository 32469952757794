import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { scrollToPoint } from 'utils/scrollToPoint';

import styles from './Footer.module.scss';

export const FooterLegal = ({ copyright, company }) => {
  const year = new Date().getFullYear();
  scrollToPoint();
  return (
    <section className={classnames('wrap__hundred', styles.legal)}>
      <div className={classnames('wrap__xxl', styles.inner)}>
        {copyright ? (
          <div className={styles.copyright}>
            <p
              className={'font-copyright'}
              dangerouslySetInnerHTML={{ __html: `© ${copyright} ${year}` }}
            ></p>
          </div>
        ) : null}
        {company ? (
          <div className={styles.by}>
            {company.map((item, i) => {
              return (
                <a
                  key={`brought-by-${i}`}
                  href={item.link.url}
                  target="_BLANK"
                  rel="noopener noreferrer"
                >
                  <img src={item.logo.mediaItemUrl} alt={item.name} />
                </a>
              );
            })}
          </div>
        ) : null}

        <div className={styles.disclaimer}>
          <ul className={'font-disclaimer'}>
            <li>
              <Link to="/disclaimer-and-privacy-policy" className={'link'}>
                Disclaimer & Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FooterLegal;
