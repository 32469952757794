import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';

import Button from 'components/Button';
import { ImageFadeIn } from 'components/Image';
import Carousel from 'components/Carousel';

import { formatDate } from 'utils/formatDate';

import styles from './Featurenewsandevents.module.scss';

export const Featurenewsandevents = ({ articleOne, articleTwo }) => {
  const title = 'News and Events';
  const [width] = useWindowSize();

  // posts.edges.map(post => { //eslint-disable-line
  //   if (Number(post.node.postId) === Number(articleOne)) {
  //     articleOneContent = post.node;
  //   }

  //   if (Number(post.node.postId) === Number(articleTwo)) {
  //     articleTwoContent = post.node;
  //   }
  // });

  const slides = [
    {
      image: articleOne.featuredImage.mediaItemUrl,
      title: articleOne.title,
      url: `/news-and-events/${articleOne.slug}`,
    },
    {
      image: articleTwo.featuredImage.mediaItemUrl,
      title: articleTwo.title,
      url: `/news-and-events/${articleTwo.slug}`,
    },
  ];

  const params = {
    autoplay: { delay: 4500 },
    effect: 'fade',
    speed: 1500,
    loop: true,
    containerClass: 'news-events-swiper',
    navigation: {
      nextEl: '.news-events-swiper-button-next',
      prevEl: '.news-events-swiper-button-prev',
    },
    slidesPerView: 1,
    freeModeMomentum: true,
    renderPrevButton: () => (
      <div className="news-events-swiper-button-prev">Prev</div>
    ),
    renderNextButton: () => (
      <div className="news-events-swiper-button-next">Next</div>
    ),
  };

  if (!articleOne || !articleTwo) return null;
  return (
    <section className={classnames('wrap__max', styles.newsAndEvents)}>
      <div className={classnames('wrap__variant--six', styles.inner)}>
        <h2 className={'headings-font-display-5'}>
          {title ? title : 'News and Events'}
        </h2>
        {width >= 768 ? (
          <>
            <div className={styles.col__one}>
              <Link to={`/news-and-events/${articleOne.slug}`}>
                <div className={styles.image}>
                  <div className={styles.inner}>
                    <ImageFadeIn url={articleOne.featuredImage.mediaItemUrl} />
                    <div className={styles.overlay}>
                      <h4 dangerouslySetInnerHTML={{ __html: articleOne.title }}></h4>
                      <h6>{formatDate(articleOne.date)}</h6>
                    </div>
                  </div>
                </div>
              </Link>
              <div className={styles.button}>
                <Button
                  type="internal"
                  text={'View all Articles'}
                  color="primary"
                  link={'/news-and-events'}
                  size="med"
                />
              </div>
            </div>
            <div className={styles.col__two}>
              <Link to={`/news-and-events/${articleTwo.slug}`}>
                <div className={styles.image}>
                  <div className={styles.inner}>
                    <ImageFadeIn
                      url={articleTwo.featuredImage.mediaItemUrl}
                      backgroundPosition={'center'}
                    />
                    <div className={styles.overlay}>
                      <h4 dangerouslySetInnerHTML={{ __html: articleTwo.title }}></h4>
                      <h6>{formatDate(articleTwo.date)}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className={styles.carousel}>
              <Carousel params={params} type="news-events" slides={slides} />
            </div>
            <div className={styles.button}>
              <Button
                type="internal"
                text={'View all Articles'}
                color="primary"
                link={'/news-and-events'}
                size="med"
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Featurenewsandevents;
