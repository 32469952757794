import React from "react";
import classnames from "classnames";
import Button from "components/Button";

import { FooterLegal } from "components/Footer";

import pattern from "assets/images/content/pattern.png";

import styles from "./Footer.module.scss";

export const FooterRegister = ({ copyright, company, pageSlug }) => {
  return (
    <section
      className={classnames("wrap__hundred", styles.register)}
      style={{ backgroundImage: `url(${pattern})` }}
    >
      {pageSlug !== "landing" && (
        <div className={classnames("wrap__xxl", styles.inner)}>
          <h3 className={classnames(styles.events, "headings-font-display-5")}>
            <span className={classnames("underline", "primary")}>
              <Button
                type="register-underline"
                text="Register now"
                color="primary"
                link="register"
              />
            </span>
            &nbsp;for
            <br /> up coming events <br /> and early releases.
          </h3>
          <Button
            color="primary"
            text="Register"
            type="register"
            link="register"
            size="small"
          />
        </div>
      )}
      <FooterLegal copyright={copyright} company={company} />
    </section>
  );
};

export default FooterRegister;
