// graphql api url
const hostname = window.location.origin;

let url = "http://localhost:8080/graphql";

if (process.env.NODE_ENV !== "development") {
  url = hostname + "/graphql";
}

// If we're running on Docker, use the WordPress container hostname instead of localhost.
if (process.env.HOME === "/home/node") {
  url = "http://wp-headless:8080/graphql";
}
//url = "https://styvesstaging.wpengine.com/graphql"; //comment while pushing to staging
//url = 'https://www.styves.com.au/graphql'; //comment while pushing live

const Config = {
  gqlUrl: url
};

export default Config;