import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import useWindowSize from "@react-hook/window-size";

import VisibilitySensor from "react-visibility-sensor";
import classnames from "classnames";

import Button from "components/Button";

import styles from "./Carousel.module.scss";

export const Carousel = ({
  type,
  params,
  slides,
  btnLink,
  btnText,
  contentTitle
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);
  const [contentSwiperOne, getContentSwiperOne] = useState(null);
  const [contentSwiperTwo, getContentSwiperTwo] = useState(null);
  const [galleryMobileSwiperText, getGalleryMobileSwiperText] = useState(null);
  const [galleryMobileSwiperImages, getGalleryMobileSwiperImages] = useState(
    null
  );
  const [width] = useWindowSize();
  const breakPoint = 768;

  //params for two slider
  const paramsOne = {
    autoplay: { delay: 4500 },
    effect: "fade",
    speed: 1500,
    loop: true,
    containerClass: "one-swiper",
    slidesPerView: 1,
    freeModeMomentum: true
  };

  const paramsTwo = {
    autoplay: { delay: 4500 },
    effect: "fade",
    speed: 1500,
    loop: true,
    containerClass: "two-swiper",
    slidesPerView: 1,
    freeModeMomentum: true
  };

  //params for contentslider
  //params for two slider
  const paramsContentSliderOne = {
    getSwiper: getContentSwiperTwo,
    autoplay: { delay: 7000 },
    effect: "fade",
    speed: 1500,
    loop: true,
    containerClass: "one-content-swiper",
    slidesPerView: 1,
    freeModeMomentum: true,
    navigation: {
      nextEl: ".two-content-swiper-button-next",
      prevEl: ".two-content-swiper-button-prev"
    },
    renderPrevButton: () => (
      <div className="two-content-swiper-button-prev">Prev</div>
    ),
    renderNextButton: () => (
      <div className="two-content-swiper-button-next">Next</div>
    )
  };

  const paramsContentSliderTwo = {
    getSwiper: getContentSwiperOne,
    autoplay: { delay: 7000 },
    effect: "fade",
    speed: 1500,
    loop: true,
    containerClass: "two-content-swiper",

    slidesPerView: 1,
    freeModeMomentum: true
  };

  //params gallery mobile
  const paramsGalleryMobileText = {
    getSwiper: getGalleryMobileSwiperImages,
    autoplay: { delay: 6000 },
    effect: "fade",
    speed: 1500,
    loop: true,
    containerClass: "text-swiper",
    slidesPerView: 1,
    freeModeMomentum: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function(index, className) {
        return '<span class="' + className + '">0' + (index + 1) + "</span>";
      }
    }
  };

  //params gallery mobile
  const paramsGalleryMobileImages = {
    getSwiper: getGalleryMobileSwiperText,
    autoplay: { delay: 6000 },
    effect: "fade",
    speed: 1500,
    loop: true,
    containerClass: "images-swiper",
    slidesPerView: 1,
    freeModeMomentum: true
  };

  const onChange = visiblity => {
    setVisibility(visiblity);
  };

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      contentSwiperOne !== null &&
      contentSwiperOne.controller &&
      contentSwiperTwo !== null &&
      contentSwiperTwo.controller
    ) {
      contentSwiperOne.controller.control = contentSwiperTwo;
      contentSwiperTwo.controller.control = contentSwiperOne;
    }
  }, [contentSwiperTwo, contentSwiperOne]);

  useEffect(() => {
    if (
      galleryMobileSwiperText !== null &&
      galleryMobileSwiperText.controller &&
      galleryMobileSwiperImages !== null &&
      galleryMobileSwiperImages.controller
    ) {
      galleryMobileSwiperText.controller.control = galleryMobileSwiperImages;
      galleryMobileSwiperImages.controller.control = galleryMobileSwiperText;
    }
  }, [galleryMobileSwiperImages, galleryMobileSwiperText]);

  if (!slides) return null;

  const hostname = window.location.origin;
  const isHomePage = window.location.pathname === "/";
  const cdn = "https://oqp62qqcqfpii9v2fzxnxyu-wpengine.netdna-ssl.com";

  if (type === "heroHome") {
    return width > breakPoint ? (
      <div className={styles.heroHome}>
        <Swiper {...params}>
          {slides.map((image, i) => {
            const bgImage = isHomePage
              ? image.desktop.mediaItemUrl.replace(hostname, cdn)
              : image.desktop.mediaItemUrl;

            return (
              <div key={`hero-home-slide-${i}`}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${bgImage})`
                  }}
                ></div>
              </div>
            );
          })}
        </Swiper>
      </div>
    ) : (
      <div className={styles.heroHome}>
        <Swiper {...params}>
          {slides.map((image, i) => {
            return (
              <div key={`hero-home-mobile-slide-${i}`}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${image.mobile.mediaItemUrl})`
                  }}
                ></div>
              </div>
            );
          })}
        </Swiper>
      </div>
    );
  }

  if (type === "feature") {
    return width > breakPoint ? (
      <div className={classnames(styles.feature)}>
        <Swiper {...params}>
          {slides.map((image, i) => {
            return (
              <div
                key={`feature-slider-desk-${i}`}
                style={{
                  backgroundImage: `url(${image.desktop.mediaItemUrl})`
                }}
              ></div>
            );
          })}
        </Swiper>
      </div>
    ) : (
      <div className={styles.feature}>
        <Swiper {...params}>
          {slides.map((image, i) => {
            return (
              <div
                key={`feature-slider-mob-${i}`}
                style={{ backgroundImage: `url(${image.mobile.mediaItemUrl})` }}
              ></div>
            );
          })}
        </Swiper>
      </div>
    );
  }

  if (type === "text") {
    return (
      <div className={styles.text}>
        <Swiper {...params}>
          {slides.map((slide, i) => {
            return (
              <div key={`text-slider-${i}`}>
                <h4>{slide}</h4>
              </div>
            );
          })}
        </Swiper>
      </div>
    );
  }

  if (type === "two") {
    return (
      <>
        <div className={styles.swiperOne}>
          <Swiper {...paramsOne}>
            {slides.map((slide, i) => {
              return (
                <div key={`one-slider-${i}`}>
                  <div
                    className={styles.oneImage}
                    style={{ backgroundImage: `url(${slide.imageOne})` }}
                  ></div>
                </div>
              );
            })}
          </Swiper>
          <div className={styles.block}></div>
        </div>
        <div className={styles.swiperTwo}>
          <Swiper {...paramsTwo}>
            {slides.map((slide, i) => {
              return (
                <div key={`two-slider-${i}`}>
                  <div
                    className={styles.twoImage}
                    style={{ backgroundImage: `url(${slide.imageTwo})` }}
                  ></div>
                </div>
              );
            })}
          </Swiper>
          <div className={styles.block}></div>
        </div>
      </>
    );
  }

  if (type === "contentslider") {
    return (
      <div className={styles.contentSlider}>
        <div className={styles.content}>
          {contentTitle ? (
            <span
              className={classnames(
                "font-content-description",
                styles.contentTitle
              )}
            >
              {contentTitle}
            </span>
          ) : null}
          <Swiper {...paramsContentSliderOne}>
            {slides.map((slide, i) => {
              return (
                <div key={`content-slider-one-desktop-${i}`}>
                  {slide.heading ? (
                    <h2 className={"headings-font-display-3"}>
                      {slide.heading}
                    </h2>
                  ) : null}
                  {slide.subHeading ? (
                    <h3 className={"font-base-md"}>{slide.subHeading}</h3>
                  ) : null}
                </div>
              );
            })}
          </Swiper>
          {btnText ? (
            <div className={styles.button}>
              <Button
                type="internal"
                color="white"
                text={btnText}
                link={btnLink}
                size={"med"}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.contentSliderImages}>
          {width < breakPoint ? (
            <Swiper {...paramsContentSliderTwo}>
              {slides.map((image, i) => {
                return (
                  <div
                    key={`content-slider-two-mob-${i}`}
                    style={{
                      backgroundImage: `url(${image.mobile.mediaItemUrl})`
                    }}
                  ></div>
                );
              })}
            </Swiper>
          ) : (
            <Swiper {...paramsContentSliderTwo}>
              {slides.map((image, i) => {
                return (
                  <div
                    key={`content-slider-two-desk-${i}`}
                    style={{
                      backgroundImage: `url(${image.desktop.mediaItemUrl})`
                    }}
                  ></div>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    );
  }

  if (type === "news-events") {
    return (
      <div className={styles.newsEvents}>
        <Swiper {...params}>
          {slides.map((slide, i) => {
            return (
              <Link key={`news-events-slider-${i}`} to={slide.url}>
                <div className={styles.newsEvents__image}>
                  <div
                    style={{ backgroundImage: `url(${slide.image})` }}
                    className={styles.newsEvents__thumb}
                  ></div>
                  <div className={styles.newsEvents__hover}></div>
                </div>
                <div className={styles.newsEvents__title}>
                  <h4>{slide.title}</h4>
                </div>
              </Link>
            );
          })}
        </Swiper>
      </div>
    );
  }

  if (type === "gallery-mobile") {
    return (
      <>
        <div className={styles.galleryMobileText}>
          <Swiper {...paramsGalleryMobileText}>
            {slides[0].map((slide, i) => {
              return (
                <div key={`text-slider-${i}`}>
                  <h4>{slide}</h4>
                </div>
              );
            })}
          </Swiper>
        </div>
        <VisibilitySensor
          onChange={onChange}
          partialVisibility
          offset={{ top: 500 }}
        >
          <div
            className={classnames(
              styles.galleryMobileImages,
              entered ? styles.fadeIn : null
            )}
          >
            <Swiper {...paramsGalleryMobileImages}>
              {slides[1].map((slide, i) => {
                return (
                  <div key={`gallery-image-slider-${i}`}>
                    <div
                      className={styles.image}
                      style={{ backgroundImage: `url('${slide}')` }}
                    ></div>
                  </div>
                );
              })}
            </Swiper>
          </div>
        </VisibilitySensor>
      </>
    );
  }

  return <div>Carousel</div>;
};

export default Carousel;
