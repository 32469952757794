import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import Icon from "components/Icon";

import { stripBaseUrl } from "utils/stripBaseUrl";

import styles from "./Footer.module.scss";
// import downloadsStageRealease from 'assets/downloads/stage-release.pdf';
// import downloadsMasterplan from 'assets/downloads/masterplan.pdf';
// import downloadsDesignGuidelines from 'assets/downloads/design-guidelines-v2.pdf';

export const About = ({
  address,
  contactEmail,
  contactNumber,
  openHours,
  googleMapsAddressLink,
  rlaNumber,
  download,
  social
}) => {
  // const downloads = [
  //   { label: 'Masterplan', link: downloadsMasterplan },
  //   // { label: 'Stage Release', link: downloadsStageRealease },
  //   { label: 'Design Guidelines', link: downloadsDesignGuidelines },
  // ];

  const googleUrl = googleMapsAddressLink ? googleMapsAddressLink.url : null;

  const stripWhiteSpace = (item) => {
    return item.replace(/\s+/g, "");
  };
  return (
    <section className={classnames("wrap__hundred", styles.about)}>
      <div className={classnames("wrap__xxl", styles.inner)}>
        <div className={styles.logoWrap}>
          <div className={styles.logo}>
            <Link to="/">
              <Icon name={"logoLandscape"} fill={"primary"} />
            </Link>
          </div>
        </div>

        <div className={styles.sales}>
          <a
            href={googleUrl}
            rel="noopener noreferrer"
            target="_blank"
            className={classnames(styles.heading, "font-sales")}
          >
            Visit our sales Gallery
          </a>
          <p className={"font-address"}>
            {openHours ? (
              <>
                <span dangerouslySetInnerHTML={{ __html: openHours }} /> <br />
              </>
            ) : null}
          </p>
          <p className={"font-address"}>
            {address ? (
              <>
                {address}
                <br />
                <a
                  href={googleUrl}
                  className={"link"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Get directions
                </a>
              </>
            ) : null}
          </p>
          <p className={"font-address"}>
            {contactNumber ? (
              <>
                Contact us on{" "}
                <a
                  href={`tel:${stripWhiteSpace(contactNumber)}`}
                  className={classnames("font-address", "link")}
                >
                  {contactNumber}
                </a>{" "}
              </>
            ) : null}
            {contactEmail ? (
              <>
                or
                <br />
                via email{" "}
                <a
                  href={`mailto:${contactEmail}`}
                  className={classnames("link", "font-address")}
                >
                  {contactEmail}
                </a>
                <br />
              </>
            ) : null}
            {rlaNumber ? <>RLA {rlaNumber} </> : null}
          </p>

          {download ? (
            <p className={"font-address"}>
              <span>
                <Link to={"/downloads"} className={styles.downloadsTitle}>
                  Downloads
                </Link>
              </span>
              <br />
              {download.map((item, i) => {
                return (
                  <a
                    key={`downloads-${i}`}
                    href={
                      item.file.target === "_blank"
                        ? item.file.url
                        : stripBaseUrl(item.file.url)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classnames(styles.downloads, "link")}
                  >
                    {item.label}
                  </a>
                );
              })}
            </p>
          ) : null}
        </div>

        <div className={styles.socials}>
          {social ? (
            <>
              {social.map((item, i) => {
                return (
                  <a
                    key={`socials-${i}`}
                    className={styles.social}
                    href={item.link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={item.icon.mediaItemUrl} alt={item.label} />
                  </a>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default About;
