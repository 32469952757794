import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import Icon from "components/Icon";

import { stripBaseUrl } from "utils/stripBaseUrl";

import styles from "./Footer.module.scss";

export const LandingAbout = ({
  address,
  contactEmail,
  contactNumber,
  openHours,
  googleMapsAddressLink,
  rlaNumber,
  download,
  social
}) => {
  const googleUrl = googleMapsAddressLink ? googleMapsAddressLink.url : null;

  const stripWhiteSpace = (item) => {
    return item.replace(/\s+/g, "");
  };

  return (
    <section className={classnames("wrap__hundred", styles.about)}>
      <div className={classnames("wrap__xxl", styles.inner, styles.landing)}>
        <div className={styles.logoWrap}>
          <div className={styles.logo}>
            <Link to="/">
              <Icon name={"logoLandscape"} fill={"primary"} />
            </Link>
          </div>
        </div>

        <div className={classnames(styles.sales, styles.landing)}>
          <div className={styles.salesCenter}>
            <a
              href={googleUrl}
              rel="noopener noreferrer"
              target="_blank"
              className={classnames(styles.heading, "font-sales")}
            >
              Visit our sales Center
            </a>
            <p className={"font-address"}>
              {openHours ? (
                <>
                  <span dangerouslySetInnerHTML={{ __html: openHours }} />{" "}
                  <br />
                </>
              ) : null}
            </p>
            <p className={"font-address"}>
              {address ? (
                <>
                  {address}
                  <br />
                  <a
                    href={googleUrl}
                    className={"link"}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Get directions
                  </a>
                </>
              ) : null}
            </p>
          </div>
          <div className={styles.contact}>
            <Link to={"/contact"} target="_blank" className={classnames(styles.heading, "font-sales")}>
              Contact
            </Link>
            <p className={"font-address"}>
              {contactNumber ? (
                <>
                  Contact us on{" "}
                  <a
                    href={`tel:${stripWhiteSpace(contactNumber)}`}
                    className={classnames("font-address", "link")}
                  >
                    {contactNumber}
                  </a>{" "}
                </>
              ) : null}
              {contactEmail ? (
                <>
                  or
                  <br />
                  via email{" "}
                  <a
                    href={`mailto:${contactEmail}`}
                    className={classnames("link", "font-address")}
                  >
                    {contactEmail}
                  </a>
                  <br />
                </>
              ) : null}
              {rlaNumber ? <>RLA {rlaNumber} </> : null}
            </p>
          </div>
          <div className={styles.download}>
            {download ? (
              <p className={"font-address"}>
                <Link to={"/downloads"} target="_blank" className={classnames(styles.downloadsTitle, "font-sales")}>
                  Downloads
                </Link>
                <br />
                  {download.map((item, i) => {
                    return (
                      <div className={styles.downloadLinkBtn}>
                        <a
                          key={`downloads-${i}`}
                          href={
                            item.file.target === "_blank"
                              ? item.file.url
                              : stripBaseUrl(item.file.url)
                          }
                          target={item.file.target}
                          rel="noopener noreferrer"
                          className={classnames(styles.downloads, "link")}
                        >
                          {item.label}
                        </a>
                      </div>
                    );
                  })}
              </p>
            ) : null}
          </div>
        </div>

        <div className={classnames(styles.socials, styles.landing)}>
          {social ? (
            <>
              {social.map((item, i) => {
                return (
                  <a
                    key={`socials-${i}`}
                    className={styles.social}
                    href={item.link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={item.icon.mediaItemUrl} alt={item.label} />
                  </a>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default LandingAbout;
