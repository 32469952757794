import React from "react";
import classnames from "classnames";
import styles from "./Builderinfo.module.scss";

export const Builderinfo = ({ ...props }) => {
  const { paragraph, address, directionsLink, openingHours } = props;

  return (
    <section className={classnames(styles.bannerSection)}>
      <div className={classnames(styles.container, styles.return, "wrap__xxl")}>
        <a className={classnames(styles.link, "font-base")} href="/display-village">← Return</a>
      </div>
      <div className={classnames(styles.container, "wrap__xxl")}>
        <p className={classnames(styles.paragraph, "font-base")}>{paragraph}</p>
        <p
          className={classnames(styles.paragraph, styles.address, "font-base")}
        >
          Address: <br />
          {address} <br />
          <a
            className={classnames(styles.link, "font-base")}
            target="_blank"
            rel="noopener noreferrer"
            href={directionsLink}
          >
            Get Directions →
          </a>
        </p>
        <p className={classnames(styles.paragraph, styles.hours, "font-base")}>
          Opening Hours: <br />
          {openingHours} <br />
        </p>
      </div>
    </section>
  );
};

export default Builderinfo;
