import React from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';

import ImageFadeIn from 'components/Image';

import styles from './Aboutus.module.scss';

export const Aboutus = ({
  aboutLogo,
  aboutHeading,
  aboutSubHeading,
  aboutFeatureText,
  aboutImage
}) => {
  const [width] = useWindowSize();
  const breakPoint = 768;
  return (
    <section className={classnames(styles.aboutUs, 'wrap__max')}>
      <div className={classnames(styles.inner, 'wrap__variant--seven')}>
        <div className={styles.top}>
          <h1 className={'font-modal'}>{aboutHeading}</h1>
          <h2 className={'headings-font-display-6'}>{aboutSubHeading}</h2>
          <div className={styles.iconDesk}>
            {width >= breakPoint ? (
              <img src={aboutLogo.mediaItemUrl} alt="" />
            ) : null}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.image}>
            <div className={styles.imageInner}>
              {width < breakPoint ? (
                <ImageFadeIn url={aboutImage.mobile.mediaItemUrl} />
              ) : (
                <ImageFadeIn url={aboutImage.desktop.mediaItemUrl} />
              )}
              <div className={styles.block}></div>
            </div>
          </div>
          <div className={styles.logo}>
            <div className={styles.logoInner}>
              {width < breakPoint ? (
                <img src={aboutLogo.mediaItemUrl} alt="" />
              ) : null}
            </div>
          </div>
          <div className={styles.featureText}>
            <h3
              className={'headings-font-display-1__variant--two'}
              dangerouslySetInnerHTML={{ __html: aboutFeatureText }}
            ></h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
