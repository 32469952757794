import React from 'react';
import classnames from 'classnames';

import styles from './Heading.module.scss';

export const HeadingNowSelling = ({title}) => {
  return (
    <section className={classnames('wrap__max',styles.headingNowSelling)}>
      <div className={classnames('wrap__xxl', styles.inner)}>
        <h3 className={'font-modal'}>{title}</h3>
        {/* <h1 className={'headings-font-display-6'}>Now Selling - Stage 1</h1> */}
      </div>
    </section>
  );
};

export default HeadingNowSelling;
