import React from "react";
import classnames from "classnames";
import styles from "./Builderbanner.module.scss";

export const Builderbanner = ({ ...props }) => {
  const { paragraph, builderLogo } = props;

  return (
    <section className={classnames(styles.bannerSection)}>
      <div className={classnames(styles.container, "wrap__xxl")}>
        <div className={classnames(styles.logoWrapper )}>
          <img alt="" src={builderLogo.mediaItemUrl} />
        </div>
        <h2 className={classnames(styles.title, "headings-font-display-2")}>
          {paragraph}
        </h2>
      </div>
    </section>
  );
};

export default Builderbanner;
