import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import styles from './Image.module.scss';

export const ImageFadeIn = ({ url, backgroundPosition }) => {
  const [isVisible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);

  const onChange = (visiblity) => {
    setVisibility(visiblity);
  };

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  return (
    <VisibilitySensor
      onChange={onChange}
      partialVisibility
      offset={{ top: 500 }}
    >
      <div className={classnames(entered ? styles.fadeIn : null, styles.image)}>
        <div
          className={classnames(
            styles.inner,
            backgroundPosition ? styles[backgroundPosition] : null,
          )}
          style={{ backgroundImage: `url(${url})` }}
        />
      </div>
    </VisibilitySensor>
  );
};

export default ImageFadeIn;
