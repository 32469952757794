import React from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';

import styles from './HeadingArticle.module.scss';

export const HeadingArticle = ({ title, date, bgcolor, color, goBack }) => {
  return (
    <section className={classnames(styles.headingArticle, 'wrap__hundred')}>
      <div
        className={classnames(styles.wrap, 'wrap__max')}
        style={{ backgroundColor: bgcolor }}
      >
        <div className={classnames(styles.inner, 'wrap__xxl')}>
          <div className={styles.goback} onClick={goBack}>
            <div className={styles.goback__icon}>
              <Icon name="arrow" fill="grey" />
            </div>
            <span
              className={classnames('font-disclaimer', styles.goback__text)}
            >
              Back
            </span>
          </div>
          <div className={styles.title}>
            <h1 style={{ color }} className={'headings-font-display-7'} dangerouslySetInnerHTML={{ __html: title}} />
            <h4>{date}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeadingArticle;
