import React from 'react';
import classnames from 'classnames';

import ImageFadeIn from 'components/Image';

import styles from './Discoverroseworthy.module.scss';

export const Discoverroseworthy = ({
  discoverHeading,
  discoverDescription,
  discoverBlockTitle,
  discoverImageOne,
  discoverImageTwo,
}) => {
  if (!discoverHeading && !discoverImageOne) return null;
  return (
    <section className={classnames('wrap__max', styles.discoverRoseworthy)}>
      <div className={classnames('wrap__variant--six', styles.inner)}>
        <h2 className={'headings-font-display-5'}>{discoverBlockTitle}</h2>
        <div className={styles.col__one}>
          <div className={styles.image}>
            <div className={styles.inner}>
              <ImageFadeIn url={discoverImageOne.mediaItemUrl} />
            </div>
          </div>
          <div className={styles.copy}>
            {discoverHeading ? (
              <h3 className={'font-base__variant--one'}>{discoverHeading}</h3>
            ) : null}
            {discoverDescription ? (
              <p className={'font-base__variant--one'}>{discoverDescription}</p>
            ) : null}
          </div>
        </div>
        <div className={styles.col__two}>
          <div className={styles.image}>
            <div className={styles.inner}>
              <ImageFadeIn url={discoverImageTwo.mediaItemUrl} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discoverroseworthy;
