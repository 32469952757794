import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Seo from "components/Seo";
import { ThemeContext } from 'theme/theme';

import { stripWhiteSpace } from 'utils/stripWhiteSpace';
import ScrollToTop from 'utils/ScrollToTop';

import Button from 'components/Button';
import Register from 'components/Register';
import ImageFadeIn from 'components/Image';
import Tag from 'components/Tag';

import styles from './contact.module.scss';
/**
 * GraphQL page query
 * Gets page's title and content using slug as uri
 */
const PAGE_QUERY = gql`
  query MyQuery {
    pageBy(uri: "/contact") {
      title
      seo {
        metaDesc
        title
      }
      acf_layout_contact {
        content {
          contactHeading
          contactTitle
          fieldGroupName
          image {
            desktop {
              mediaItemUrl
            }
            mobile {
              mediaItemUrl
            }
          }
        }
      }
    }
    themeSettings {
      theme_settings {
        address
        contactEmail
        contactNumber
        openHours
        googleMapsAddressLink {
          url
        }
      }
    }
  }
`;

export const Contact = props => {
  const { transparentHeader, setWhiteHeader } = useContext(ThemeContext);

  const [pageState, setPageState] = useState({
    page: {
      title: '',
      seo: '',
      content: '',
      pageId: null,
      uri: '',
      acf_layout_contact: {
        content: null,
      },
    },
  });

  const [themeState, setThemeState] = useState({ theme: [] });

  const breakPoint = 768;
  const [width] = useWindowSize();

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePageQuery = async () => {
      const { match, client } = props;
     
      let uri = match.params.slug;
      if (!uri) {
        uri = 'welcome';
      }
      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        const themeSettings = result.data.themeSettings;
        setPageState({ ...pageState, page });
        setThemeState({ ...themeState, themeSettings });
      }

    };

    // Execute the page query using uri and set the state
    executePageQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  useEffect(() => {
    setWhiteHeader();
  }, [transparentHeader]); // eslint-disable-line

  const { page } = pageState;

  const { themeSettings } = themeState;
  const acf_blocks = page.acf_layout_contact.content;

  const mobileImage = acf_blocks ? (
    <div className={styles.image__mob}>
      <div className={styles.tag}>
        <Tag />
      </div>
      <ImageFadeIn url={acf_blocks.image.mobile.mediaItemUrl} />
      <div className={styles.block}></div>
    </div>
  ) : null;
  const deskImage = acf_blocks ? (
    <div className={styles.image__desk}>
      <ImageFadeIn url={acf_blocks.image.desktop.mediaItemUrl} />
      <div className={styles.block}></div>
    </div>
  ) : null;

  return (
    <section className={classnames(styles.contact, 'wrap__hundred')}>
      <Seo title={page.title} yoast={page.seo} />
     <ScrollToTop />
      <div className={classnames('wrap__xxl', styles.inner)}>
        <div className={styles.title}>
          <h1 className={'font-content-description'}>
            {acf_blocks ? acf_blocks.contactTitle : null}
          </h1>
        </div>

        <div className={styles.quote}>
          <h2 className={'headings-font-display-6'}>
            {acf_blocks ? acf_blocks.contactHeading : null}
          </h2>
        </div>
        <div className={styles.form}>
          <Register />
        </div>
        <div className={styles.image}>
          {width < breakPoint ? mobileImage : deskImage}
        </div>
        <div className={styles.location}>
          {themeSettings ? (
            <>
              <p
                className={'font-base'}
                dangerouslySetInnerHTML={{
                  __html: themeSettings.theme_settings.openHours,
                }}
              ></p>

              <p className={'font-base'}>
                <span className={'font-base'}
                  dangerouslySetInnerHTML={{
                    __html: themeSettings.theme_settings.address,
                  }}
                ></span>
                <br/>
                <a
                  href={
                    themeSettings
                      ? themeSettings.theme_settings.googleMapsAddressLink.url
                      : null
                  }
                  target="_BLANK"
                  rel="noopener noreferrer"
                  className={classnames(styles.link)}
                >
                  Get directions
                </a>
              </p>

              <p className={'font-base'}>
                Contact us on{' '}
                <a
                  href={`tel:${
                    themeSettings
                      ? stripWhiteSpace(
                          themeSettings.theme_settings.contactNumber,
                        )
                      : null
                  }`}
                  className={styles.link}
                  dangerouslySetInnerHTML={{
                    __html: themeSettings.theme_settings.contactNumber,
                  }}
                />
                or via email{' '}
                <a
                  href={`mailto:${
                    themeSettings
                      ? themeSettings.theme_settings.contactEmail
                      : null
                  }?subject=Contact from Website`}
                  target="_BLANK"
                  rel="noopener noreferrer"
                  className={styles.link}
                  dangerouslySetInnerHTML={{
                    __html: themeSettings.theme_settings.contactEmail,
                  }}
                />
              </p>
            </>
          ) : null}
          <div className={styles.button}>
            <Button
              type={'external'}
              color={'black'}
              text={'View Google Map'}
              link={
                themeSettings
                  ? themeSettings.theme_settings.googleMapsAddressLink.url
                  : null
              }
              size={'med'}
            />
          </div>
        </div>
        <div className={styles.tag}>
          <Tag />
        </div>
      </div>
    </section>
  );
};

export default withApollo(Contact);
