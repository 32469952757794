import React from "react";
import classnames from "classnames";
import styles from "./builders.module.scss";
import Button from "components/Button";

export const Builders = ({ ...props }) => {
  const {
    heading,
    introduction,
    downloadButton,
    downloadButtonLink,
    builderIndividual,
  } = props;

  return (
    <section className={classnames(styles.builderSection)}>
      <div className={classnames(styles.container, "wrap__xxl")}>
        <div className={classnames(styles.sideBar)}>
          <h2 className="headings-font-display-2">{heading}</h2>
          <p className="font-base">{introduction}</p>
          {!!downloadButton && (
            <Button
              color="primary"
              text={downloadButton}
              type="external"
              link={downloadButtonLink}
              size="med"
              className={classnames(styles.download)}
            />
          )}
        </div>
        <div className={classnames(styles.builderGrid)}>
          {builderIndividual.map((builder, i) => {
            const { builderLink, builderLogo, builderName, nowOpen } = builder;
            // console.log(builderLink);
            return (
              <a
                className={classnames(
                  styles.builderItem,
                  nowOpen && styles.builderHover
                )}
                key={i}
                href={nowOpen && builderLink.uri}
              >
                <div className={classnames(styles.logoWrapper)}>
                  <img alt="" src={builderLogo.mediaItemUrl} />
                  {!!nowOpen && <div className={classnames(styles.accent)} />}
                </div>
                <div className={classnames(styles.builderInfo)}>
                  <h6 className="font-nav">{builderName}</h6>
                  {!!nowOpen ? (
                    <span
                      className={classnames(styles.tag, "font-base")}
                      style={{ color: "#f69783" }}
                    >
                      Now open
                    </span>
                  ) : (
                    <span
                      className={classnames(styles.tag, "font-base")}
                      style={{ color: "#231d16" }}
                    >
                      Coming soon
                    </span>
                  )}
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Builders;
