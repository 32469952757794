import React, { useContext, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { ThemeContext } from 'theme/theme';
import ScrollToTop from 'utils/ScrollToTop';
import { Helmet } from "react-helmet";

import { HeadingSmall } from 'components/Heading';
import Listings from 'components/Listings';

import styles from './NewsAndEvents.module.scss';

const POST_QUERY = gql`
  query MyQuery {
    pageBy(uri: "/news-and-events") {
      acf_layout_news {
        contentNews {
          featureQuote
        }
      }
    }
    posts {
      edges {
        node {
          id
          slug
          title
          categories {
            nodes {
              name
              slug
              categoryId
            }
          }
          excerpt
          featuredImage {
            mediaItemUrl
          }
        }
      }
    }
    categories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

export const Newsandevents = props => {
  const [state, setState] = useState({
    categories: null,
    posts: null,
    quote: null,
  });

  const { transparentHeader, setWhiteHeader } = useContext(ThemeContext);
  const headingBgColor = '#faeae5';
  const headingColor = '#525657';
  const heading = 'News and Events';

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePostQuery = async () => {
      const { match, client } = props;
      const filter = match.params.slug;
      const result = await client.query({
        query: POST_QUERY,
        variables: { filter },
      });
      if (!requestCancelled) {
        const quote =
          result.data.pageBy.acf_layout_news.contentNews.featureQuote;
        const categories = result.data.categories;
        const posts = result.data.posts;

        setState({ categories, posts, quote });
      }
    };

    // Execute post query, process the response and set the state
    executePostQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      setWhiteHeader();
    }, 1000)
  }, [transparentHeader]); // eslint-disable-line

  useEffect(() => {
      setWhiteHeader();
  }, [window.location.pathname]); // eslint-disable-line

  const { posts, categories, quote } = state; // eslint-disable-line

  if (!posts) return null;

  return (
    <section className={classnames('wrap__hundred', styles.newsEvents)}>
      <Helmet>
        <title itemProp="name" lang="en">
          St Yves | News and Events
        </title>
      </Helmet>
      <ScrollToTop />
      <HeadingSmall
        bgcolor={headingBgColor}
        color={headingColor}
        heading={heading}
      />
      <Listings listings={posts} quote={quote} />
    </section>
  );
};

export default withApollo(Newsandevents);
