import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export const Seo = ({ title, yoast }) => {
  const pageTitle = yoast.title ? yoast.title : title;

  useEffect(() => {
    if (window.dataLayer && pageTitle) {
      // console.log(data.seo.title);
      window.dataLayer.push({
        event: "title-loaded",
        title: pageTitle,
      });
      // console.log("datalayer pushed!");
    }
  }, [pageTitle]); // eslint-disable-line

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={yoast.metaDesc} />
    </Helmet>
  );
};

export default Seo;
