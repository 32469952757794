import React from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import useWindowSize from '@react-hook/window-size';

import ImageFadeIn from 'components/Image';
import { TagRoseworthy } from 'components/Tag';

import { stripBaseUrl } from 'utils/stripBaseUrl';

import styles from './Imagetextwithwatermark.module.scss';

// import imageOneMobileUrl from 'assets/images/content/watermark-image-mob.jpg';
// import imageOneDesktopUrl from 'assets/images/content/watermark-image-desk.jpg';

export const Imagetextwithwatermark = ({
  button,
  heading,
  copy,
  image,
  buttonLeaderText,
  ...props
}) => {
  const [width] = useWindowSize();
  const breakPoint = 768;
  const cta = (
    <div className={styles.cta}>
      <h3
        className={"headings-font-display-5"}
        dangerouslySetInnerHTML={{ __html: buttonLeaderText }}
      ></h3>
      <div className="button">
        <Button
          color={button.colour}
          text={button.label}
          type={button.type}
          size="med"
          link={button.link && stripBaseUrl(button.link.url)}
        />
      </div>
      <div className={styles.tagRoseworthy}>
        <TagRoseworthy />
      </div>
    </div>
  );
  return (
    <section className={classnames("wrap__max", styles.imageTextWithWatermark)}>
      <div className={classnames(styles.top, "wrap__variant--three")}>
        <div className={styles.heading}>
          <h2 className={"headings-font-display-3"}>{heading}</h2>
        </div>

        <div className={styles.image}>
          <div className={styles.inner}>
            {width < breakPoint ? (
              <ImageFadeIn url={image.mobile && image.mobile.mediaItemUrl} />
            ) : (
              <ImageFadeIn url={image.desktop && image.desktop.mediaItemUrl} />
            )}
          </div>
        </div>
        {width < breakPoint ? cta : null}
      </div>
      <div className={classnames(styles.bottom)}>
        <div className={styles.paragraph}>
          <p
            className={"font-base-md"}
            dangerouslySetInnerHTML={{ __html: copy }}
          ></p>
        </div>
        {width >= breakPoint ? cta : null}
      </div>
    </section>
  );
};

export default Imagetextwithwatermark;
