import React, { useCallback, useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import classnames from "classnames";
import propertyAppConfig from "react-property-app/config";

import Modal from "../Modal";
import Register from "../Register";
import Icon from "../Icon";

import { ThemeContext } from "theme/theme";

import styles from "./Button.module.scss";

export const Button = ({
  color,
  type,
  link,
  text,
  size,
  fullWidth,
  registerToggle,
  className
}) => {
  const {
    colors,
    setCloseButtonTrue,
    closeButton,
    showRegisterModalText,
    registerFormLeaderText
  } = useContext(ThemeContext);

  const [isOpen, setOpen] = useState(false);
  const [pkg, setPkg] = useState(null);

  let newColor;

  const toggleOpen = useCallback(
    pkg => {
      if (closeButton) setOpen(false);

      if (!isOpen) {
        setOpen(true);
        setCloseButtonTrue();
      }

      setPkg(pkg);
    },
    [closeButton, isOpen, setCloseButtonTrue]
  );

  useEffect(() => {
    if (type === "register") {
      propertyAppConfig.set("enquiryForm", {
        function: toggleOpen
      });
    }
  }, [toggleOpen, type]);

  useEffect(() => {
    if (!closeButton) {
      setOpen(false);
    }
  }, [closeButton]);

  if (color && color.startsWith("#")) {
    newColor = color;
  } else if (color) {
    colors.forEach(item => {
      if (color === item.name) {
        newColor = item.color;
      }
    });
  } else {
    newColor = "#F69784";
  }

  if (!type && !link) return null;
  if (type === "internal") {
    return (
      <Link
        to={link}
        style={{ backgroundColor: newColor }}
        className={classnames(
          styles.button,
          styles[size],
          styles[color],
          fullWidth ? styles.full : null,
          className
        )}
      >
        <span>{text}</span>
      </Link>
    );
  }

  if (type === "external") {
    return (
      <a
        href={link}
        target="_BLANK"
        rel="noopener noreferrer"
        style={{ backgroundColor: newColor }}
        className={classnames(
          className,
          styles.button,
          styles[size],
          styles[color],
          fullWidth ? styles.full : null,
          className
        )}
      >
        <span>{text}</span>
      </a>
    );
  }

  if (type === "email") {
    return (
      <a
        href={`mailto:${link}`}
        style={{ backgroundColor: newColor }}
        className={classnames(
          styles.button,
          styles[size],
          styles[color],
          fullWidth ? styles.full : null,
          className
        )}
      >
        <span>{text}</span>
      </a>
    );
  }

  if (type === "tel") {
    return (
      <a
        href={`tel:${link}`}
        style={{ backgroundColor: newColor }}
        className={classnames(
          styles.button,
          styles[size],
          styles[color],
          fullWidth ? styles.full : null,
          className
        )}
      >
        <span>{text}</span>
      </a>
    );
  }

  if (type === "register") {
    const { name } = pkg || {};

    return (
      <>
        <button
          onClick={toggleOpen}
          style={{ backgroundColor: newColor }}
          className={classnames(
            styles.button,
            styles[size],
            styles[color],
            fullWidth ? styles.full : null,
            className
          )}
        >
          <span>{text ? text : "register"}</span>
        </button>

        {ReactDOM.createPortal(
          <div className={classnames(styles.modal, "wrap__hundred")}>
            <div className={classnames(styles.inner, "wrap__xxl")}>
              <div className={styles.modalRegister}>
                <Modal isOpen={isOpen}>
                  <div className="wrap__xxl">
                    <>
                      {name && showRegisterModalText ? (
                        <h3
                          className={"headings-font-display-3"}
                          style={{ lineHeight: 1.4, fontSize: "1.3rem" }}
                        >
                          Fill in your details to find out
                          <br /> more information on {name}
                        </h3>
                      ) : showRegisterModalText ? (
                        <h3
                          className={"headings-font-display-3"}
                          style={{ lineHeight: 1.4, fontSize: "1.3rem" }}
                          dangerouslySetInnerHTML={{
                            __html: registerFormLeaderText
                          }}
                        />
                      ) : null}

                      <Register isOpen={isOpen} pkg={pkg} howDoYouWishToBeContacted={false}/>
                    </>
                  </div>
                </Modal>
              </div>
            </div>
          </div>,
          document.body
        )}
      </>
    );
  }

  if (type === "register-underline") {
    return (
      <>
        <span
          onClick={toggleOpen}
          style={{ color: newColor }}
          className={classnames(
            styles.regUnderline,
            styles[size],
            styles[color]
          )}
        >
          <span>{text}</span>
        </span>
        <div className={classnames(styles.modal, "wrap__hundred")}>
          <div className={classnames(styles.inner, "wrap__xxl")}>
            <div className={styles.modalRegister}>
              <Modal isOpen={isOpen}>
                <div className="wrap__xxl">
                  <>
                    <h3 className={"headings-font-display-3"}>
                      Fill in your details and our
                      <br /> team will contact you shortly.
                    </h3>
                    <Register isOpen={isOpen} howDoYouWishToBeContacted={false}/>
                  </>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (type === "download") {
    return (
      <a
        href={link}
        target="_blank"
        style={{ backgroundColor: newColor }}
        rel="noopener noreferrer"
        className={classnames(
          styles.button,
          styles.outline,
          styles.download,
          styles[size],
          styles[color],
          fullWidth ? styles.full : null
        )}
      >
        <span>{text}</span>
        <span className={styles.icon}>
          <Icon name="download" fill="black" />
        </span>
      </a>
    );
  }

  if (type === "none") {
    return <button>{text}</button>;
  }
  return <div>button</div>;
};

export default Button;
