import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ArticlePagination.module.scss';

export const ArticlePagination = ({ prevArticle, nextArticle }) => {
  return (
    <section className={styles.articlePagination}>
      <div className={styles.inner}>
        {prevArticle ? (
          <Link
            to={'/news-and-events/' + prevArticle.node.slug}
            className={styles.prev}
          >
            <span className={styles.prev__articleTag}>
              Previous <br className={styles.showMob} />
              Article
            </span>
            <div className={styles.prev__content}>
              <h4>{prevArticle.node.title}</h4>
            </div>
            <div className={styles.prev__arrow}>
              <svg viewBox="0 0 44 28" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.702.2l1.912 1.976L5.72 12.403H43.8v2.794H5.829l9.896 10.227-1.912 1.976L.6 13.744z"
                  fill="#000"
                />
              </svg>
            </div>
          </Link>
        ) : null}
        {nextArticle ? (
          <Link
            to={'/news-and-events/' + nextArticle.node.slug}
            className={styles.next}
            style={{
              backgroundImage: `url(${nextArticle.node.featuredImage.mediaItemUrl})`,
            }}
          >
            <span className={styles.next__articleTag}>
              Next <br className={styles.showMob} />
              Article
            </span>
            <div className={styles.next__content}>
              <h4>{nextArticle.node.title}</h4>
            </div>
            <div className={styles.next__overlay}></div>
            <div className={styles.next__arrow}>
              <svg viewBox="0 0 44 28" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M30.298.2l-1.912 1.976 9.893 10.227H.2v2.794h37.971l-9.896 10.227 1.912 1.976L43.4 13.744z"
                  fill="#000"
                />
              </svg>
            </div>
          </Link>
        ) : null}
      </div>
    </section>
  );
};

export default ArticlePagination;
