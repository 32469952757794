import React, { useContext, useEffect, useState } from "react";
import useWindowSize from "@react-hook/window-size";
import classnames from "classnames";
import Button from "components/Button";
import { Link } from "react-router-dom";

import Carousel from "components/Carousel";
import Register from "components/Register";

import { ThemeContext } from "theme/theme";

import ScrollToTop from "utils/ScrollToTop";
import VideoBg from "reactjs-videobg";
import styles from "./Heroslider.module.scss";

export const Heroslider = ({
  images,
  enableVideo,
  videoBackground,
  videoBackgroundMobile,
  heading,
  subHeading,
  button,
  centreText,
  showButton,
  stamp,
  stampLink,
  showButtonSecondary,
  buttonSecondary,
  showForm,
  form,
  styling,
  ...props
}) => {
  const { setHeroHeight, setTransparentHeader } = useContext(ThemeContext);
  const [width] = useWindowSize();
  const [heroImageHeight, setHeroImageHeight] = useState();
  // const [activeVideo, setActiveVideo] = useState(videoBackground);
  const params = {
    autoplay: { delay: 4500 },
    effect: "fade",
    speed: 1500,
    loop: true,
    rebuildOnUpdate: true,
    containerClass: "hero-swiper",
    // navigation: {
    //   nextEl: '.hero-swiper-button-next',
    //   prevEl: '.hero-swiper-button-prev',
    // },
    slidesPerView: 1,
    freeModeMomentum: true,
    // renderPrevButton: () => <div className='hero-swiper-button-prev'>Prev</div>,
    // renderNextButton: () => <div className='hero-swiper-button-next'>Next</div>,
  };

  useEffect(() => {
    setHeroHeight(heroImageHeight);
  }, [heroImageHeight, width]); //eslint-disable-line
  useEffect(() => {
    setTransparentHeader();
    const desktopVideo =
      enableVideo && document.getElementsByClassName("video-desktop")[0];
    const mobileVideo =
      enableVideo && document.getElementsByClassName("video-mobile")[0];
      if(desktopVideo) {
        desktopVideo.setAttribute("playsinline","true");
        desktopVideo.oncanplaythrough = function() {
          desktopVideo.muted = true;
          desktopVideo.play();
        };
      }
      if(mobileVideo) {
        mobileVideo.setAttribute("playsinline","true");
        mobileVideo.oncanplaythrough = function() {
          mobileVideo.muted = true;
          mobileVideo.play();
        };
      }
  }, []); // eslint-disable-line
  return (
    <section
      className={classnames("wrap__max", styles.heroSlider, {
        [styles.heroWithForm]: showForm,
        [styles.small]: styling === "small",
      })}
      ref={(el) => {
        if (!el) return;

        let prevValue = JSON.stringify(el.getBoundingClientRect());
        const handle = setInterval(() => {
          let nextValue = JSON.stringify(el.getBoundingClientRect());
          if (nextValue === prevValue) {
            clearInterval(handle);
            setHeroImageHeight(el.getBoundingClientRect().height);
          } else {
            prevValue = nextValue;
          }
        }, 100);
      }}
    >
      <ScrollToTop />
      {enableVideo ? (
        <>
        <VideoBg
          poster={images[0].desktop.mediaItemUrl}
          wrapperClass={styles.heroVideoWrapper}
          videoClass={classnames('hero-video video-desktop',styles.heroVideo)}
        >
          <VideoBg.Source src={videoBackground.mediaItemUrl} type="video/mp4" />
        </VideoBg>
        <VideoBg
          poster={images[0].mobile.mediaItemUrl}
          wrapperClass={styles.heroVideoWrapperMobile}
          videoClass={classnames('hero-video video-mobile',styles.heroVideo)}
        >
          <VideoBg.Source src={videoBackgroundMobile.mediaItemUrl} type="video/mp4" />
        </VideoBg>
        </>
      ) : (
        <Carousel type="heroHome" params={params} slides={images} />
      )}
      <div className={classnames("wrap__xxl", styles.content)}>
        <div className={styles.contentCol}>
          {heading ? (
            <h1
              className={classnames(
                centreText ? styles.center : null,
                "headings-font-display-2",
                styles.heading
              )}
              dangerouslySetInnerHTML={{ __html: heading }}
            ></h1>
          ) : null}
          {subHeading ? (
            <h2
              className={classnames("headings-font-display-4", styles.heading)}
              dangerouslySetInnerHTML={{ __html: subHeading }}
            ></h2>
          ) : null}
          {showButton && button.label ? (
            <>
              <Button
                color={button.colour}
                text={button.label}
                type={button.type}
                link={button.link ? button.link.url : "register"}
                size="med"
                className={styles.heroslider}
              />
            </>
          ) : null}
          {showButtonSecondary && buttonSecondary.label ? (
            <>
              <br />
              <Button
                color={buttonSecondary.colour}
                text={buttonSecondary.label}
                type={buttonSecondary.type}
                link={
                  buttonSecondary.link ? buttonSecondary.link.url : "register"
                }
                size="med"
                className={styles.heroslider}
              />
            </>
          ) : null}
        </div>
        {showForm ? (
          <div className={styles.registerFormWrapper}>
            {form ? (
              <h3
                className="headings-font-display-3"
                style={{
                  lineHeight: 1.4,
                  fontSize: "1.3rem",
                  marginBottom: "1rem",
                }}
              >
                {form.headline}
              </h3>
            ) : null}
            <Register
              className={styles.registerForm}
              howDoYouWishToBeContacted={false}
            />
          </div>
        ) : null}
        {stamp && stamp.mediaItemUrl ? (
          stampLink ? (
            <Link to={stampLink.url}>
              <img
                src={stamp.mediaItemUrl}
                className={styles.stamp}
                alt="Promotion"
              />
            </Link>
          ) : (
            <img
              src={stamp.mediaItemUrl}
              className={styles.stamp}
              alt="Promotion"
            />
          )
        ) : null}
      </div>
    </section>
  );
};

export default Heroslider;
