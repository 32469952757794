import React from 'react';
import classnames from 'classnames';

import styles from './Wysiwyg.module.scss';

export const Wysiwyg = ({ copy, version }) => {
  return (
    <div
      className={classnames(
        version === 'article' ? styles.article : null,
        styles.wysiwyg,
      )}
      dangerouslySetInnerHTML={{ __html: copy }}
    ></div>
  );
};

export default Wysiwyg;
