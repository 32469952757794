import React from "react";
import classnames from "classnames";
import sift from "sift";
import useApi from "react-property-app/api";
import Package from "react-property-app/components/Package";

import Tag from "components/Tag";
import styles from "./Propertyapp.module.scss";

export const Propertyapp = ({ packages: filter = "" }) => {
  const slugs = filter ? filter.replace(/ /g, "").split(",") : [];

  const api = useApi();
  const packages = slugs.length
    ? api.packages.list().filter(
        sift({
          slug: { $in: slugs }
        })
      )
    : [];

  return (
    <section className={classnames(styles.propertyApp, "wrap__max")}>
      <div className={classnames(styles.innerMain, "wrap__hundred")}>
        <div className={styles.tag}>
          <Tag />
        </div>

        <div className={classnames(styles.content, "wrap__variant--seven")}>
          {packages.map((pkg, i) => (
            <Package key={pkg.slug + i} package={pkg} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Propertyapp;
