import React, { useContext, useEffect } from "react";
import { PropertyAppProvider } from "react-property-app";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import { ThemeContext } from "theme";
import PropertyApp from "react-property-app";
import { HeadingNowSelling } from "components/Heading";

import styles from "./HouseAndLand.module.scss";

export const HouseAndLand = () => {
  const { transparentHeader, setWhiteHeader } = useContext(ThemeContext);

  useEffect(() => {
    setTimeout(() => {
      setWhiteHeader();
    }, 1000);
  }, [transparentHeader]); // eslint-disable-line

  useEffect(() => {
    setWhiteHeader();
  }, [window.location.pathname]); // eslint-disable-line

  return (
    <PropertyAppProvider>
      <section className={classnames("wrap__max houseandland", styles.wrapper)}>
        <Helmet>
          <title itemProp="name" lang="en">
            St Yves | House and Land
          </title>
        </Helmet>
        <HeadingNowSelling title="House and land" className={styles.heading} />
        <PropertyApp />
      </section>
    </PropertyAppProvider>
  );
};

export default HouseAndLand;
