import React, { useState, useEffect, useContext } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Seo from "components/Seo";

import classnames from 'classnames';
import HeadingArticle from 'components/HeadingArticle';
import {
  ArticleContent,
  ArticleCategories,
  ArticlePagination,
} from 'components/ArticleContent';
import { ThemeContext } from 'theme/theme';

import { formatDate } from 'utils/formatDate';
import ScrollToTop from 'utils/ScrollToTop';

import styles from './Post.module.scss';

/**
 * GraphQL post query that takes a post slug as a filter
 * Returns the title, content and author of the post
 */
const POST_QUERY = gql`
  query PostQuery($filter: String!) {
    postBy(slug: $filter) {
      id
      title
      seo {
        metaDesc
        title
      }
      acf_post {
        contentPost {
          wysiwyg
        }
      }
      featuredImage {
        mediaItemUrl
      }
      categories {
        edges {
          node {
            categoryId
          }
        }
      }
      excerpt
      date
    }
    posts {
      edges {
        node {
          id
          slug
          title
          categories {
            nodes {
              name
              slug
              categoryId
            }
          }
          excerpt
          featuredImage {
            mediaItemUrl
          }
        }
      }
    }
    categories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    themeSettings {
      theme_settings {
        address
        contactEmail
        contactNumber
        fieldGroupName
        openHours
        rlaNumber
        social {
          fieldGroupName
          label
          link {
            url
          }
          icon {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

/**
 * Fetch and display a Post
 */
export const Post = props => {
  const [state, setState] = useState({
    post: null,
    categories: null,
    posts: null,
    themeSettings: null,
  });

  const { transparentHeader, setWhiteHeader, newsEventsBackgroundHeight } = useContext(
    ThemeContext,
  );

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePostQuery = async () => {
      const { match, client } = props;
      const filter = match.params.slug;
      const result = await client.query({
        query: POST_QUERY,
        variables: { filter },
      });
      if (!requestCancelled) {
        const post = result.data.postBy;
        const categories = result.data.categories;
        const posts = result.data.posts;
        const themeSettings = result.data.themeSettings;

        setState({ post, categories, posts, themeSettings });
      }
    };

    // Execute post query, process the response and set the state
    executePostQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      setWhiteHeader();
    }, 1000)
  }, [transparentHeader]); // eslint-disable-line

  useEffect(() => {
      setWhiteHeader();
  }, [window.location.pathname]); // eslint-disable-line

  //categories, TODO
  const { post, posts, themeSettings } = state; // eslint-disable-line

  if (!post) {
    return null;
  } else {
    const findPostIndex = id => {
      let index;
      // eslint-disable-next-line
      posts.edges.map((post, i) => {
        if (post.node.id === id) {
          index = i;
        }
      });
      return index;
    };

    const postIndex = findPostIndex(post.id);
    const siblingPosts = [
      posts.edges[postIndex - 1],
      posts.edges[postIndex + 1],
    ];

    const findSimilarPosts = selectedPostCategories => {
      const results = [];
      // eslint-disable-next-line
      posts.edges.map((individualPost, i) => {
        // eslint-disable-next-line
        individualPost.node.categories.nodes.map(individualPostCat => {
          if (
            individualPostCat.categoryId ===
              selectedPostCategories.edges[0].node.categoryId &&
            i !== postIndex
          ) {
            results.push(individualPost);
          }
        });
      });

      return results;
    };

    const similarPosts = findSimilarPosts(post.categories);
    
    return (
      <>
        <Seo title={post.title} yoast={post.seo} />
        <ScrollToTop />
        <HeadingArticle
          title={post.title}
          date={formatDate(post.date)}
          bgcolor={'#faeae5'}
          color={'#525657'}
          goBack={props.history.goBack}
        />

        <section className={classnames(styles.article, 'wrap__hundred')}>
          <div className={classnames(styles.wrap, 'wrap__max')}>
            <div
              className={styles.background}
              style={{
                height:
                  newsEventsBackgroundHeight > 0
                    ? `${newsEventsBackgroundHeight}px`
                    : null,
              }}
            ></div>
            <div className={classnames('wrap__xxl', styles.inner)}>
              <div className={styles.content}>
                <ArticleContent
                  title={post.title}
                  hero={post.featuredImage.mediaItemUrl}
                  excerpt={post.excerpt}
                  copy={post.acf_post.contentPost.wysiwyg}
                  themeSettings={themeSettings}
                />
              </div>
              <div className={styles.categories}>
                <ArticleCategories similarStories={similarPosts} />
              </div>
              <div className={styles.pagination}>
                <ArticlePagination
                  nextArticle={siblingPosts[0]}
                  prevArticle={siblingPosts[1]}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default withApollo(Post);
