import React from "react";
import classnames from "classnames";
import styles from "./Displayhomes.module.scss";
import Button from "components/Button";
import bedIcon from "assets/images/content/bed.svg";
import bathIcon from "assets/images/content/bath.svg";
import carIcon from "assets/images/content/car.svg";

export const Displayhomes = ({ ...props }) => {
  const { title, homes } = props;
  //console.log(homes);
  return (
    <section className={classnames(styles.ctaSection)}>
      <div className={classnames(styles.container, "wrap__xxl")}>
        <h2 className={classnames(styles.title, "headings-font-display-2")}>
          {title}
        </h2>
        <div className={classnames(styles.homeGrid)}>
          {homes.map((home, i) => {
            const {
              title,
              image,
              address,
              bed,
              bath,
              car,
              buttonLabel,
              buttonLink,
            } = home;

            return (
              <div className={classnames(styles.homeCard)}>
                <img
                  alt=""
                  className={classnames(styles.image)}
                  src={image.mediaItemUrl}
                />
                <h4>{title}</h4>
                <div className={classnames(styles.detailWrapper)}>
                  <span>{address}</span>
                  <div className={classnames(styles.icons)}>
                    <div className={classnames(styles.iconWrapper)}>
                      <span>{bed}</span>
                      <img alt="bed" src={bedIcon} />
                    </div>
                    <div className={classnames(styles.iconWrapper)}>
                      <span>{bath}</span>
                      <img alt="bath" src={bathIcon} />
                    </div>
                    <div className={classnames(styles.iconWrapper)}>
                      <span>{car}</span>
                      <img alt="car" src={carIcon} />
                    </div>
                  </div>
                </div>
                {buttonLabel &&  (
                  <div className={classnames(styles.buttonWrapper)}>
                    <Button
                      color="primary"
                      text={buttonLabel}
                      type="external"
                      link={buttonLink}
                      size="med"
                    />
                  </div>
                ) }
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Displayhomes;
