//
// Icons
//
import email from "assets/images/icons/email.svg";
import facebook from "assets/images/icons/facebook.svg";
import bedroom from "assets/images/icons/bedroom.svg";
import bathroom from "assets/images/icons/bathroom.svg";
import garage from "assets/images/icons/garage.svg";
import arrow_orange from "assets/images/icons/arrow_orange.svg";
import houseandland from "assets/images/icons/houseandland.svg";

window.traffic = {
  ...(window.traffic || {}),
  style: {
    icons: {
      houseandland,
      // land,
      bedroom,
      bathroom,
      garage,
      // whatsapp,
      arrow_orange,

      // disabled icons
      email,
      facebook
    }
  }
};
