import React from "react";
import styles from "./Twocolumntextimage.module.scss";
import { Tag } from "components/Tag";
import classnames from "classnames";

export const Twocolumntextimage = ({
  tagText,
  title,
  headline,
  details,
  image,
  overlayText
}) => {
  return (
    <div className={styles.twoColumnTextImageWrapper}>
      <div className={styles.tag}>
        <Tag />
      </div>
      <div className={classnames('wrap__variant--three', styles.twoColumnTextImageContainer)}>
        <div className={styles.textBlock}>
          {title && <h3>{title}</h3>}
          {headline && <h2>{headline}</h2>}
          {details && <p>{details}</p>}
        </div>
        <div className={styles.imageBlock}>
          {image.mediaItemUrl && <img alt="" src={image.mediaItemUrl} />}
          {overlayText && <div className={styles.overlay}>{overlayText}</div>}
        </div>
      </div>
    </div>
  );
};

export default Twocolumntextimage;
