import React from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';

import ImageFadeIn from 'components/Image';
import { Tag, TagRoseworthy } from 'components/Tag';

import styles from './Introduction.module.scss';

export const Introduction = ({
  imageOne,
  imageTwo,
  copy,
  heading,
  featureText,
}) => {
  const [width] = useWindowSize();
  const breakPoint = 1024;
  // add the body copy to this var as it gets moved
  const paragraph = (
    <div
      className={classnames('font-base', styles.paragraph)}
      dangerouslySetInnerHTML={{ __html: copy }}
    ></div>
  );

  if (!imageTwo || !imageOne) return null;

  return (
    <section className={classnames(styles.introduction, 'wrap__max')}>
      <div className={styles.tag}>
        <Tag />
      </div>
      <div className={styles.tagRoseworthy}>
        <TagRoseworthy />
      </div>
      <div className={classnames(styles.inner, 'wrap__variant--one')}>
        <div className={styles.blockquote}>
          <h3
            className={'headings-font-display-3'}
            dangerouslySetInnerHTML={{ __html: heading }}
          ></h3>
        </div>
        <div className={styles.left}>
          <div className={styles.imageOne}>
            {width < breakPoint ? (
              <ImageFadeIn url={imageOne.mobile.mediaItemUrl} />
            ) : (
              <ImageFadeIn url={imageOne.desktop.mediaItemUrl} />
            )}
          </div>
          <div className={styles.featureText}>
            <h2
              className={'headings-font-display-1'}
              dangerouslySetInnerHTML={{ __html: featureText }}
            ></h2>
          </div>
        </div>
        <div className={styles.right}>
          {width >= breakPoint ? paragraph : null}
          <div className={styles.imageTwo}>
            {width < breakPoint ? (
              <ImageFadeIn url={imageTwo.mobile.mediaItemUrl} />
            ) : (
              <ImageFadeIn url={imageTwo.desktop.mediaItemUrl} />
            )}
          </div>
        </div>
        {width < breakPoint ? paragraph : null}
      </div>
    </section>
  );
};

export default Introduction;
