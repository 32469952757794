import React, { useState, useContext, useEffect } from "react";
import classnames from "classnames";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import Seo from "components/Seo";
import { ThemeContext } from "theme/theme";
import ScrollToTop from "utils/ScrollToTop";

import { TagRoseworthy } from "components/Tag";
import pdfIcon from "assets/images/icons/st_yves_pdf_icon.svg";
import styles from "./downloads.module.scss";
/**
 * GraphQL page query
 * Gets page's title and content using slug as uri
 */
const PAGE_QUERY = gql`
  query MyQuery {
    pageBy(uri: "/downloads") {
      id
      title
      acf_layout_downloads {
        section {
          fieldGroupName
          heading
          downloads {
            title
            link {
              mediaItemUrl
            }
          }
        }
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;

export const Downloads = (props) => {
  const { transparentHeader, setWhiteHeader } = useContext(ThemeContext);

  const [pageState, setPageState] = useState({
    page: {
      title: "",
      seo: "",
      content: "",
      pageId: null,
      uri: "",
      acf_layout_downloads: {
        content: null,
      },
    },
  });

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePageQuery = async () => {
      const { match, client } = props;

      let uri = match.params.slug;
      if (!uri) {
        uri = "welcome";
      }
      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        setPageState({ ...pageState, page });
      }
    };

    // Execute the page query using uri and set the state
    executePageQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      setWhiteHeader();
    }, 1000);
  }, [transparentHeader]); // eslint-disable-line

  useEffect(() => {
    setWhiteHeader();
  }, [window.location.pathname]); // eslint-disable-line

  const [showAll, setShowAll] = useState(false);
  const { page } = pageState;

  if (!page.title) return null;

  return (
    <section className={classnames(styles.downloads, "wrap__hundred")}>
      <Seo title={page.title} yoast={page.seo} />
      <ScrollToTop />
      <div className={classnames("wrap__xxl", styles.inner)}>
        <h1
          className={"font-modal"}
          dangerouslySetInnerHTML={{ __html: page.title }}
        />
        <div className={styles.tagRoseworthy}>
          <TagRoseworthy />
        </div>
        <div className={styles.content}>
          {page &&
            page.acf_layout_downloads.section.map((section, i) => {
              const downloads = showAll
                ? section.downloads
                : section.downloads.slice(0, 6);
              return (
                <div key={`downloads-section-${i}`} className={styles.section}>
                  <h4
                    className={"headings-font-display-8"}
                    dangerouslySetInnerHTML={{ __html: section.heading }}
                  />
                  {section.downloads && (
                    <div className={classnames(styles.downloadGrid)}>
                      {downloads.map((download, j) => {
                        return (
                          <a
                            href={download.link.mediaItemUrl}
                            rel="noopener noreferrer"
                            target={"_blank"}
                            key={`download-${j}`}
                            className={styles.download}
                          >
                            <div className={styles.iconWrapper}>
                              <img alt="" src={pdfIcon} />
                            </div>
                            <span
                              className={classnames(
                                "font-base__variant--one",
                                styles.link
                              )}
                            >
                              {download.title}
                            </span>
                          </a>
                        );
                      })}
                      {section.downloads.length > 6 && (
                        <button
                          className={classnames(styles.viewMore)}
                          onClick={() => {
                            setShowAll(!showAll);
                          }}
                        >
                          <span>
                            {showAll
                              ? "View less"
                              : `View more (${section.downloads.length - 6})`}
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default withApollo(Downloads);
