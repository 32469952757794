import React from "react";
import Icon from "components/Icon";

import styles from "./Tag.module.scss";

export const Tag = (props) => {
  return (
    <div className={styles.tag}>
      <h4 className={"font-tag"}>
        {props.title ? props.title : "A Natural Advantage"}
      </h4>
      <div className={styles.icon}>
        <Icon name={"favicon"} fill={"primary"} />
      </div>
    </div>
  );
};

export default Tag;
