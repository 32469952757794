import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import ScrollToTop from 'utils/ScrollToTop';
import { ThemeContext } from 'theme/theme';
import Seo from "components/Seo";
import Wysiwyg from 'components/Wysiwyg';

import styles from './DisclaimerAndPrivacyPolicy.module.scss';
/**
 * GraphQL page query
 * Gets page's title and content using slug as uri
 */
const PAGE_QUERY = gql`
  query MyQuery {
    pageBy(uri: "/disclaimer-and-privacy-policy") {
      title
      seo {
        metaDesc
        title
      }
      acf_layout_disclaimer {
        contentDisclaimer {
          wysiwyg
        }
      }
    }
  }
`;

export const DisclaimerAndPrivacyPolicy = props => {
  const { transparentHeader, setWhiteHeader } = useContext(ThemeContext);

  const [pageState, setPageState] = useState({
    page: null,
  });

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePageQuery = async () => {
      const { match, client } = props;
     
      let uri = match.params.slug;
      if (!uri) {
        uri = 'welcome';
      }
      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        setPageState({ ...pageState, page });
      }
    };

    // Execute the page query using uri and set the state
    executePageQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  useEffect(() => {
    setWhiteHeader();
  }, [transparentHeader]); // eslint-disable-line

  const { page } = pageState;

  

  if (!page) {
    return null;
  } else {
    return (
      <section className={classnames(styles.disclaimer, 'wrap__hundred')}>
        <Seo title={page.title} yoast={page.seo} />
        <ScrollToTop />
        <div className={'wrap__xxl'}>
          <Wysiwyg copy={page.acf_layout_disclaimer.contentDisclaimer.wysiwyg} />
        </div>
      </section>
    );
  }
};

export default withApollo(DisclaimerAndPrivacyPolicy);
