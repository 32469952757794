/** @jsx jsx **/

import classnames from "classnames";
import styles from "./Minimalhero.module.scss";
import { css, jsx } from "@emotion/react";

export const Minimalhero = ({ ...props }) => {
  const { title, background } = props;
  const bg = background.mediaItemUrl;
  // console.log(background.mediaItemUrl);
  return (
    <section
      css={css({
        backgroundImage: `url(${bg})`,
      })}
      className={classnames(styles.HeroSection)}
    >
      <h2 className={classnames(styles.title, "headings-font-display-2")}>
        {title}
      </h2>
    </section>
  );
};

export default Minimalhero;
