import React from "react";
import classnames from "classnames";
import styles from "./cta.module.scss";
import Button from "components/Button";

export const Cta = ({ ...props }) => {
  const { title, paragraph, buttonLabel } = props;

  return (
    <section className={classnames(styles.ctaSection)}>
      <div className={classnames(styles.container, "wrap__xxl")}>
        <h2 className={classnames(styles.title, "headings-font-display-2")}>
          {title}
        </h2>
        <div className={classnames(styles.contentRight)}>
          <p className={classnames(styles.paragraph, "font-base")}>
            {paragraph}
          </p>
          <Button
            color="primary"
            text={buttonLabel}
            type="register"
            size="med"
            className={classnames(styles.button)}
          />
        </div>
      </div>
    </section>
  );
};

export default Cta;
