export const stripBaseUrl = url => {
  if (url) {
    //locationOrigin: window.location.origin, //TODO: this needs to be added in for links to work before go live
    const locationOrigin = 'http://localhost:8080';
    let newUrl = url.replace(locationOrigin, '');
    return newUrl.replace(window.location.origin, '');
  }

  return url;
};
