import React from "react";
import styles from "./Contactdetails.module.scss";

export const Contactdetails = ({ title, headline, company }) => {
  return (
    <div className={styles.contactDetailsWrapper}>
      <div className={styles.contactDetailsContainer}>
        {title && <h3>{title}</h3>}
        {headline && <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>}
        {company.length > 0 && (
          <div className={styles.companies}>
            {company.map((item, index) => {
              return (
                <div key={`company-${index}`} className={styles.company}>
                  <h2>{item.name}</h2>
                  {item.logo && <img alt="logo" src={item.logo.mediaItemUrl} />}
                  <h3><a href={`tel:${item.phone}`}>{item.phone}</a></h3>
                  <a href={`mailto:${item.email}`} target="_blank" rel="noopener noreferrer">{item.email}</a>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contactdetails;
