import React from "react";
import { About, LandingAbout } from "components/Footer";

export const FooterAbout = ({ data, pageSlug }) => {
  if (pageSlug === "landing") {
    return (
      <LandingAbout
        address={data.address}
        contactEmail={data.contactEmail}
        contactNumber={data.contactNumber}
        openHours={data.openHours}
        googleMapsAddressLink={data.googleMapsAddressLink}
        rlaNumber={data.rlaNumber}
        download={data.download}
        social={data.social}
      />
    );
  }
  return (
    <About
      address={data.address}
      contactEmail={data.contactEmail}
      contactNumber={data.contactNumber}
      openHours={data.openHours}
      googleMapsAddressLink={data.googleMapsAddressLink}
      rlaNumber={data.rlaNumber}
      download={data.download}
      social={data.social}
    />
  );
};

export default FooterAbout;
