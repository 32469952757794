import React, { useState, useContext } from "react";
import classnames from "classnames";
import { Form, FormGroup } from "reactstrap";
import { ajax } from "jquery";
import { useForm } from "react-hook-form";

import { ThemeContext } from "theme/theme";

// import { do_inSiteReg } from "utils/insite";

import styles from "./Register.module.scss";

// const reaConvensionBaseScript = "https://leads.media-tools.realestate.com.au/conversions"
// const reaConvensionsSecondaryScript = "REA.track_conversion('17798');"

// const InsertReaConvensionsExtraScript = () => {
//   const scriptBase = document.createElement("script");
//   scriptBase.id = "reaCovensionBase";
//   scriptBase.type = "text/javascript";
//   scriptBase.src = reaConvensionBaseScript;
//   const newScript = document.createElement("script");
//   newScript.id = "reaConvensionsTrigger";
//   const reaScript = document.createTextNode(reaConvensionsSecondaryScript);
//   newScript.appendChild(reaScript);
//   document.body.appendChild(scriptBase);
//   document.body.appendChild(newScript);
// };

export const Register = ({ pkg, className, ...props }) => {
  const { howDoYouWishToBeContacted = true } = props;
  if (!pkg) pkg = {};
  // const pkgID = pkg.id || pkg.lot_id;
  // const pkgUrl = pkg.url ? window.location.origin + pkg.url : "";

  const { setShowRegisterModalText, 
    setCloseButtonFalse 
  } = useContext(
    ThemeContext
  );

  const { register, handleSubmit, errors, watch } = useForm();
  const [showThankYou, setShowThankYou] = useState(false);

  const [firstName, setFirstName] = useState(false);
  const [loading, setLoading] = useState(false);
  const random = Math.floor(Math.random() * 9999 + 1);

  const watchAllFields = watch();
  // TODO add insite
  const onSubmit = props => {
    // console.log("submit", props);
    const hostname = window.location.origin;
    //const formId = hostname?.includes("styvesstaging") ? "1094" : "1698";
    let formId = "1698";
    if(hostname === "https://styvesstaging.wpengine.com") {
      formId = "1094";
    }

    const cf7ApiUrl = `${hostname}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;

    var form = new FormData();
    form.append('firstName', props.firstName);
    form.append('lastName', props.lastName);
    form.append('email', props.email);
    form.append('phone', props.phone);
    form.append('postCode', props.postCode);
    form.append('purchaseTimeFrame', props.looking);
  
    var settings = {
      url: cf7ApiUrl,
      method: 'POST',
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
      data: form,
    };
    setLoading(true);
    ajax(settings)
      .done(async (data) => {
        setFirstName(firstName);
        setShowThankYou(true);
        setShowRegisterModalText(false);
        setTimeout(() => {
          setCloseButtonFalse();
        }, 2000);
        setTimeout(() => {
          setShowRegisterModalText(true);
          setShowThankYou(false);
          // setFirstName(false);
        }, 4000);
      })
      .fail(e => {
        console.log('error', e);
      })
      .always(() => {
        // setSubmitting(false);
        setLoading(false);
      });

    // const regData = {
    //   NAME: `${props.firstName} ${props.lastName}`,
    //   EMAIL: props.email,
    //   PHONE: props.phone,
    //   POST_CODE: props.postCode,
    //   PURCHASE_T: props.looking,
    //   CONTACT_BY: props.howcontacted,
    //   PKG_NAME: pkg.name,
    //   PKG_ID: pkgID,
    //   PKG_URL: pkgUrl
    // };

    // ajax({
    //   url:
    //     "https://styves.us19.list-manage.com/subscribe/post-json?u=03d159149b90ee77cbec2f4a4&id=dce70693e3&c=?",
    //   type: "GET",
    //   data: regData,
    //   dataType: "jsonp",
    //   contentType: "application/json; charset=utf-8",
    //   success: data => {
    //     setLoading(true);
    //     if (data.result !== "success") {
    //       InsertReaConvensionsExtraScript()
    //       //   let errorMssg = data.msg
    //       //   ? data.msg
    //       //     .replace('0 - ', '')
    //       //     .replace('Please enter a value', '')
    //       //   : 'Something unexpected happened. Please try again.'

    //       // const isSubscribed =
    //       //   errorMssg.indexOf('is already subscribed to list') > -1;

    //       // setError(errorMssg);

    //       // if (isSubscribed) {
    //       //   // const link = `<a ${error.split('<a ')[1]}`;
    //       //   // const link =
    //       //   errorMssg = `Thank you for registering, we already have you on our database.`;
    //       // }

    //       const errorMssg = data.msg;

    //       if (errorMssg.indexOf("is already subscribed to list") > -1) {
    //         setFirstName(firstName);
    //         setShowThankYou(true);
    //         setShowRegisterModalText(false);

    //         setTimeout(() => {
    //           setCloseButtonFalse();
    //         }, 8000);

    //         setTimeout(() => {
    //           setShowRegisterModalText(true);
    //           setShowThankYou(false);
    //         }, 10000);
    //       }
    //     } else {
    //       console.log("reg data", regData);
    //       do_inSiteReg(regData);
    //       setFirstName(firstName);
    //       setShowThankYou(true);
    //       setShowRegisterModalText(false);

    //       setTimeout(() => {
    //         setCloseButtonFalse();
    //       }, 8000);

    //       setTimeout(() => {
    //         setShowRegisterModalText(true);
    //         setShowThankYou(false);
    //       }, 10000);
    //     }
    //     setLoading(false);
    //   }
    // });

  };

  return (
    <section className={classnames(className, styles.register)}>
      {!showThankYou ? (
        <div>
          <Form onSubmit={handleSubmit(onSubmit)} id="lightbox-register">
            <div className={styles.fields}>
              <FormGroup>
                <input
                  type="text"
                  name="firstName"
                  id={`firstName${random}`}
                  className={classnames({
                    [styles.errorField]: errors.firstName,
                    [styles.active]: watchAllFields.firstName
                  })}
                  ref={register({ required: true })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`firstName${random}`}
                >
                  First name*
                </label>
                <div className={styles.error}>
                  {errors.firstName && "First name is required."}
                </div>
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  name="lastName"
                  id={`lastName${random}`}
                  ref={register({ required: true })}
                  className={classnames({
                    [styles.errorField]: errors.lastName,
                    [styles.active]: watchAllFields.lastName
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`lastName${random}`}
                >
                  Last name*
                </label>
                <div className={styles.error}>
                  {" "}
                  {errors.lastName && "Last name is required."}
                </div>
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  name="email"
                  id={`email${random}`}
                  ref={register({
                    required: true,
                    pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/ //eslint-disable-line
                  })}
                  className={classnames({
                    [styles.errorField]: errors.email,
                    [styles.active]: watchAllFields.email
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`email${random}`}
                >
                  Email Address*
                </label>
                <div className={styles.error}>
                  {" "}
                  {errors.email && "Email is required."}
                </div>
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  name="phone"
                  id={`phone${random}`}
                  ref={register({
                    pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ //eslint-disable-line
                  })}
                  className={classnames({
                    [styles.errorField]: errors.phone,
                    [styles.active]: watchAllFields.phone
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`phone${random}`}
                >
                  Mobile Number
                </label>
                <div className={styles.error}>
                  {" "}
                  {errors.phone && "Please enter a vaild Phone Number."}
                </div>
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  name="postCode"
                  id={`postCode${random}`}
                  ref={register({ pattern: /^[0-9]*$/ })}
                  className={classnames({
                    [styles.errorField]: errors.postCode,
                    [styles.active]: watchAllFields.postCode
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`postCode${random}`}
                >
                  Postcode
                </label>
                <div className={styles.error}>
                  {" "}
                  {errors.postCode && "Please enter a valid Postcode."}
                </div>
              </FormGroup>
              <FormGroup>
                <div className={styles.select}>
                  <select
                    name="looking"
                    id=""
                    ref={register({ required: true })}
                    className={classnames({
                      [styles.errorField]: errors.looking
                    })}
                  >
                    <option value="">I'm looking to buy in the next*</option>
                    <option value="1 Month">1 month</option>
                    <option value="3 Months">3 months</option>
                    <option value="12 Months">12 month</option>
                  </select>
                </div>
                <div className={styles.error}>
                  {" "}
                  {errors.looking && "Buy time is required."}
                </div>
              </FormGroup>
              {howDoYouWishToBeContacted ? (
                <FormGroup>
                  <span className={styles.title}>
                    How do you wish to be contacted?{" "}
                  </span>
                  <div className={styles.radio}>
                    <div className={styles.radioWrap}>
                      <input
                        id={`email-radio${random}`}
                        type="radio"
                        name="howcontacted"
                        value="email"
                        ref={register}
                      />
                      <label
                        className={styles.radioLabel}
                        htmlFor={`email-radio${random}`}
                      >
                        Email{" "}
                      </label>
                    </div>
                    <div className={styles.radioWrap}>
                      <input
                        id={`phone-radio${random}`}
                        type="radio"
                        name="howcontacted"
                        value="phone"
                        ref={register}
                      />
                      <label
                        className={styles.radioLabel}
                        htmlFor={`phone-radio${random}`}
                      >
                        Phone
                      </label>
                    </div>
                  </div>
                </FormGroup>
              ) : null}
              <FormGroup>
                <div className={styles.privacy}>
                  <label>
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      ref={register({ required: true })}
                      className={styles.checkbox}
                    />
                    <p>
                      I have read and accept the{" "}
                      <a
                        href="/disclaimer-and-privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy and Disclaimer
                      </a>
                    </p>

                    {/* <p>I have read and accept the {'  '}</p>
                  <a
                    href={'/'}
                    target='_BLANK'
                    rel='noopener noreferrer'
                    className={'link'}
                  >
                    {' '}
                    &nbsp;Privacy Policy
                  </a> */}
                  </label>
                </div>

                {errors.checkbox ? (
                  <div
                    className={classnames(styles.error, styles.errorPrivacy)}
                  >
                    {errors.checkbox && "Please accept."}
                  </div>
                ) : null}
              </FormGroup>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                type="submit"
                className={styles.submit}
                disabled={loading}
              >
                <span>Submit</span>
              </button>
              {loading ? (
                <div className={styles.loading}>
                  <div className={styles.animation} />
                </div>
              ) : null}
            </div>
          </Form>
        </div>
      ) : (
        <div id="register-confirm">
          <h3 className="headings-font-display-8">
            Thanks for your interest in St Yves. We’ll be in contact shortly.
          </h3>
          <img
            src="https://sasinator.realestate.com.au/rea/count/advid=17798/actname=LeadComplete"
            height="1"
            width="1"
            alt=""
          />
        </div>
      )}
    </section>
  );
};

export default Register;