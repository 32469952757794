import React, { createContext, useContext, useState } from 'react';
import useStateOnceUntil from 'hooks/useStateOnceUntil';

export const ThemeContext = createContext();

export const Theme = ({ children }) => {
  const [
    showLFSModal,
    setShowLFSModal,
    forceSetShowLFSModal,
  ] = useStateOnceUntil('tomorrow', 'lfs-modal');

  const [settings, setSettings] = useState({
    colors: [
      { name: 'primary', color: '#e8957c' },
      { name: 'secondary', color: '#fa8e76' },
      { name: 'white', color: '#fff' },
      { name: 'black', color: '#2F2C27' },
      { name: 'grey', color: '#525657' },
    ],
    transparentHeader: 'true',
    closeButton: false,
    newsEventsBackgroundHeight: '0',
    heroHeight: 0,
    registerFormLeaderText: '',
  });
  // this is use to avoid overwritting settings when running simultaneously
  const [secondarySettings, setSecondarySettings] = useState({
    showRegisterModalText: true,
  });

  return (
    <ThemeContext.Provider
      value={{
        colors: settings.colors,
        transparentHeader: settings.transparentHeader,
        closeButton: settings.closeButton,
        newsEventsBackgroundHeight: settings.newsEventsBackgroundHeight,
        heroHeight: settings.heroHeight,
        showRegisterModalText: secondarySettings.showRegisterModalText,

        // lfs-modal
        showLFSModal,
        toggleLFSModal: () => setShowLFSModal(!showLFSModal),
        forceToggleLFSModal: () => forceSetShowLFSModal(!showLFSModal),

        registerFormLeaderText: settings.registerFormLeaderText,
        setRegisterFormLeaderText: text => {
          setSettings({ ...settings, registerFormLeaderText: text });
        },
        setCloseButtonTrue: () => {
          setSettings({ ...settings, closeButton: true });
        },
        setCloseButtonFalse: () => {
          setSettings({ ...settings, closeButton: false });
        },
        setTransparentHeader: () => {
          setSettings({ ...settings, transparentHeader: 'true' });
        },
        setWhiteHeader: () => {
          setSettings({ ...settings, transparentHeader: 'false' });
        },
        setHeroHeight: height => {
          setSettings({ ...settings, heroHeight: height });
        },
        setShowRegisterModalText: bool => {
          setSecondarySettings({
            ...secondarySettings,
            showRegisterModalText: bool,
          });
        },
        setNewsEventsBackgroundHeight: height => {
          setSettings({ ...settings, newsEventsBackgroundHeight: height });
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export default Theme;
