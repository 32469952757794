import React from "react";
import classnames from "classnames";
import styles from "./Quote.module.scss";

export const Quote = ({ title, quote }) => {
  if (!quote) return null;
  return (
    <section className={classnames(styles.quote, "wrap__max")}>
      <div
        className={classnames(styles.inner, {
          [styles.hasTitle]: title,
          wrap__xxl: title,
          "": !title
        })}
      >
        {title ? (
          <>
            <h2 className={classnames(styles.title, "headings-font-display-3")}>
              {title}
            </h2>
            <p className={classnames(styles.quote)}>{quote}</p>
          </>
        ) : (
          <h2 className={"headings-font-display-3"}>{quote}</h2>
        )}
      </div>
    </section>
  );
};

export default Quote;
