import cone from 'assets/images/content/traffic.png';

const pattern = ['c', 'i', 'f', 'f', 'a', 'r', 't'];
let current = 0;
const check = e => {
  if (pattern.indexOf(e.key) < 0 || e.key !== pattern[current]) {
    current = 0;
    return;
  }

  current++;

  if (pattern.length === current) {
    current = 0;
    success();
  }
};

const success = () => {
 
  let main = document.getElementById('root');

  for (var i = 0; i < 100; i++) {
    let top = Math.random() * 100;
    let left = Math.random() * 100;
    let divInner = `<div style="position: fixed;top: ${top}vh; left: ${left}vw;background-size: cover; background-image: url('${cone}'); z-index: 100;width: 300px;height: 300px;" class="cats"></div>`;
    let newDiv = document.createElement('div');
    newDiv.innerHTML = divInner;

    setTimeout(() => {
      main.append(newDiv);
    }, i * 100);
  }
};

export const scrollToPoint = () => {
  document.addEventListener('keydown', check, false);
};
