import React from "react";
import classnames from "classnames";

import { useTheme } from "theme";
import { ModalMinimal } from "components/Modal";
import Register from "components/Register";
import Header from "components/Header";
import Footer from "components/Footer";
import styles from "./mainlayout.module.scss";

export const Default = ({ component: Component, ...props }) => {
  const { showLFSModal, toggleLFSModal } = useTheme();
  const path = window.location.pathname;

  // useEffect(() => {
  //   const path = window.location.pathname;
  //   if (path === "/" || path === "/home") toggleLFSModal();
  // }, []); // eslint-disable-line

  return (
    <div className={props.pageSlug ? `page-${props.pageSlug}` : ""}>
      <Header />
      <main
        className={classnames(
          path === "/home" ||
            path === "/discover-st-yves/" ||
            path === "/discover-st-yves" ||
            path === "/"
            ? "blackbackground"
            : null,
          "main"
        )}
      >
        {Component ? <Component {...props} /> : props.children}
        <ModalMinimal isOpen={showLFSModal} toggle={toggleLFSModal}>
          <h3
            className="headings-font-display-3"
            style={{
              lineHeight: 1.4,
              fontSize: "1.3rem",
              marginBottom: "1rem"
            }}
          >
            Register to download the brochure & price list
          </h3>
          <Register
            className={styles.registerForm}
            howDoYouWishToBeContacted={false}
          />
        </ModalMinimal>
      </main>
      <Footer pageSlug={props.pageSlug} />
    </div>
  );
};

const mainLayout = (Component) => (props) => (
  <Default {...props} component={Component} />
);

export default mainLayout;
