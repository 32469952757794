import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ArticleCategories.module.scss';

export const ArticleCategories = ({ similarStories }) => {
 
  return (
    <section className={styles.articleCategories}>
      <div className={styles.articleCategories__categories}></div>
      <div className={styles.articleCategories__similarStories}>
        <h3>Similar Stories</h3>
        <div className={styles.inner}>
          {similarStories.map((story, i) => {
        
            if (i < 2) {
              return (
                <Link key={`similar-story-${i}`} to={story.node.slug}>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: story.node.categories.nodes[0].name,
                    }}
                  ></h6>
                  <h4
                    dangerouslySetInnerHTML={{ __html: story.node.title }}
                  ></h4>
                  <p
                    dangerouslySetInnerHTML={{ __html: `${story.node.excerpt.slice(0, 150)}...` }}
                  ></p>
                </Link>
              );
            }
            return null;
          })}
        </div>
      </div>
    </section>
  );
};

export default ArticleCategories;
