import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import VisibilitySensor from 'react-visibility-sensor';
import useWindowSize from '@react-hook/window-size';

import ImageFadeIn from 'components/Image';
import Carousel from 'components/Carousel';

import { stripBaseUrl } from 'utils/stripBaseUrl';

import styles from './Featureslider.module.scss';

export const Featureslider = ({
  title,
  heading,
  subHeading,
  description,
  button,
  images,
  staticContent,
  showButton,
  ...props
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);
  const [width] = useWindowSize();
  const breakPoint = 768;
  const singleImage = images.length <= 1 ? true : false;

  const onChange = visiblity => {
    setVisibility(visiblity);
  };

  const contentSlider = !staticContent ? true : false;

  const params = {
    autoplay: { delay: 4500 },
    effect: 'fade',
    speed: 1500,
    loop: true,
    containerClass: 'feature-swiper',
    navigation: {
      nextEl: '.feature-swiper-button-next',
      prevEl: '.feature-swiper-button-prev',
    },
    slidesPerView: 1,
    freeModeMomentum: true,
    renderPrevButton: () => (
      <div className="feature-swiper-button-prev">Prev</div>
    ),
    renderNextButton: () => (
      <div className="feature-swiper-button-next">Next</div>
    ),
  };

  useEffect(() => {
    if (isVisible) {
      setEntered(true);
    }
  }, [isVisible]);

  return (
    <VisibilitySensor
      onChange={onChange}
      partialVisibility
      offset={{ top: 500 }}
    >
      <section className={classnames('wrap__max', styles.featureSlider)}>
        {!contentSlider ? (
          <>
            <div className={styles.content}>
              {heading ? (
                <span
                  className={classnames(
                    styles.heading,
                    'font-content-description',
                  )}
                >
                  {heading}
                </span>
              ) : null}
              {subHeading ? (
                <h2 className={'headings-font-display-3'}>{subHeading}</h2>
              ) : null}
              {description ? (
                <p className={'font-base-md'}>{description}</p>
              ) : null}
              {showButton && button.label ? (
                <div className={styles.button}>
                  <Button
                    type={button.type}
                    color={button.colour}
                    text={button.label}
                    link={stripBaseUrl(button.link && button.link.url)}
                    size={'med'}
                  />
                </div>
              ) : null}
            </div>
            <div
              className={classnames(
                styles.carousel,
                entered ? styles.fadeIn : null,
              )}
            >
              {singleImage ? (
                <div className={styles.image}>
                  <div className={styles.imageInner}>
                    {width < breakPoint ? (
                      <ImageFadeIn url={images[0].image_mobile} />
                    ) : (
                      <ImageFadeIn url={images[0].image} />
                    )}
                  </div>
                </div>
              ) : (
                <Carousel type="feature" params={params} slides={images} />
              )}
            </div>{' '}
          </>
        ) : showButton ? (
          <Carousel
            type="contentslider"
            contentTitle={heading}
            slides={images}
            btnLink={stripBaseUrl(button.link && button.link.url)}
            btnText={button.label}
          />
        ) : (
          <Carousel
            type="contentslider"
            contentTitle={heading}
            slides={images}
          />
        )}
      </section>
    </VisibilitySensor>
  );
};

export default Featureslider;
