export const toComponentName = text => {
  // convert "_" to spaces
  text = text.split('_').join(' ');

  return text
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join('')
    .replace('PageAcflayoutContent', '')
    .toString();
};
