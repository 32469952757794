import React from 'react';

import styles from './Tag.module.scss';

export const TagRoseworthy = () => {
  return (
    <div className={styles.tagRoseworthy}>
      <span className={'font-tag--two'}>
        St Yves &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Roseworthy
      </span>
    </div>
  );
};

export default TagRoseworthy;
