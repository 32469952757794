import React from "react";
import styles from "./Twocolumnimagelogotext.module.scss";
import classnames from "classnames";

export const Twocolumnimagelogotext = ({
  logo,
  details,
  image,
  overlayText
}) => {
  return (
    <div className={styles.twoColumnTextImageWrapper}>
      <div className={classnames('wrap__variant--eight', styles.twoColumnTextImageContainer)}>
        <div className={styles.imageBlock}>
          {image.mediaItemUrl && <img alt="" src={image.mediaItemUrl} />}
          {overlayText && <div className={styles.overlay}>{overlayText}</div>}
        </div>
        <div className={styles.textBlock}>
          {logo.mediaItemUrl && <img alt="logo" src={logo.mediaItemUrl} />}
          {details && <blockquote>{details}</blockquote>}
        </div>
      </div>
    </div>
  );
};

export default Twocolumnimagelogotext;
