import React from "react";
import classnames from "classnames";
import Button from "components/Button";

import { stripBaseUrl } from "utils/stripBaseUrl";

import styles from "./Copy.module.scss";

export const Copy = ({
  copyWysiwyg,
  copyHeading,
  copyBackgroundColour,
  copyFeatureText,
  button,
  ...props
}) => {
  const newBackColor = copyBackgroundColour ? copyBackgroundColour : "white";

  return (
    <section
      className={classnames("wrap__hundred", styles.copy, styles[newBackColor])}
    >
      <div className={classnames("wrap__max", styles[copyBackgroundColour])}>
        <div className={classnames("wrap__xxl", styles.inner)}>
          <div className={styles.colOne}>
            <h2
              className={"headings-font-display-6"}
              dangerouslySetInnerHTML={{ __html: copyHeading }}
            />
          </div>
          <div className={styles.colTwo}>
            <div
              className={styles.wysiwyg}
              dangerouslySetInnerHTML={{ __html: copyWysiwyg }}
            ></div>
            {button && button.label ? (
              <div className={styles.button}>
                <Button
                  type={button.type}
                  color={button.colour}
                  text={button.label}
                  link={stripBaseUrl(button.link && button.link.url)}
                  size="small"
                />
              </div>
            ) : null}
          </div>
          {copyFeatureText ? (
            <div className={styles.colThree}>
              <h3
                className={"headings-font-display-1__variant--two"}
                dangerouslySetInnerHTML={{ __html: copyFeatureText }}
              ></h3>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Copy;
