import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  // const { pathname } = useLocation();
 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]); // eslint-disable-line

  return null;
};

export default ScrollToTop;
