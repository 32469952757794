import React, { useContext } from 'react';

import * as iconsComponents from './IconsList';

import { ThemeContext } from 'theme/theme';

import styles from './Icon.module.scss';

export const Icon = ({ name, fill }) => {
  const Component = iconsComponents[name];
  const { colors } = useContext(ThemeContext);
  let newFill;

  if (!name || !colors) return null;

  if (fill.startsWith('#')) {
    newFill = fill;
  } else if (fill) {
    colors.forEach((color) => {
      if (fill === color.name) {
        newFill = color.color;
      }
    });
  } else {
    newFill = '#2F2C27';
  }

  return (
    <div>
      <Component className={styles.icon} style={{ fill: newFill }} />
    </div>
  );
};

export default Icon;
