import React, { useState, useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import { Helmet } from 'react-helmet';
import Picker from 'rmc-picker';
import 'rmc-picker/assets/index.css';
import { withApollo } from 'react-apollo';
import { ScrollToTop } from 'utils/ScrollToTop';

import { animateScroll as scroll } from 'react-scroll';
import gql from 'graphql-tag';

import { ThemeContext } from 'theme/theme';

import styles from './masterplan.module.scss';

// components
import Map from 'components/Map';

/**
 * GraphQL page query
 * Gets page's title and content using slug as uri
 */
const PAGE_QUERY = gql`
query MyQuery {
  pageBy(uri: "/masterplan") {
    acf_layout_masterplan {
      contentMasterplan {
        fieldGroupName
        locations {
          colour
          coordinates {
            x
            y
            xMobile
            yMobile
            mobileAdjustments
          }
          name
        }
        mapDownload {
          mediaItemUrl
        }
        masterplanDescription
        masterplanTitle
        mapImage {
          mediaItemUrl
        }
      }
      fieldGroupName
    }
  }
}

`;

const addUniqueId = (slug, array) => {
  if (!array) return null;
  return array.map((item, i) => ({
    ...item,
    id: item.id || `${slug}-${i}`,
  }));
};

export const Masterplan = props => {
  const { transparentHeader, setWhiteHeader } = useContext(ThemeContext);
  //const { mapContent, setMapContent } = useState();

  const [pageState, setPageState] = useState({
    page: null,
  });
  const [width] = useWindowSize();

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePageQuery = async () => {
      const { match, client } = props;

      let uri = match.params.slug;
      if (!uri) {
        uri = 'welcome';
      }
      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;

        setPageState({ ...pageState, page });
      }
    };

    // Execute the page query using uri and set the state
    executePageQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      setWhiteHeader();
    }, 1000)
  }, [transparentHeader]); // eslint-disable-line

  useEffect(() => {
      setWhiteHeader();
  }, [window.location.pathname]); // eslint-disable-line

  const { page } = pageState;

  const mapImage = page
    ? page.acf_layout_masterplan.contentMasterplan.mapImage.mediaItemUrl
    : null;
  const masterplanTitle = page
    ? page.acf_layout_masterplan.contentMasterplan.masterplanTitle
    : null;
  const mapDownload = page
    ? page.acf_layout_masterplan.contentMasterplan.mapDownload.mediaItemUrl
    : null;
  const locations = page
    ? page.acf_layout_masterplan.contentMasterplan.locations
    : null;

  const levels = [
    {
      image: page ? mapImage : null,
      markers: addUniqueId('location', page ? locations : null),
    },
  ];

  const markersList = useRef();
  const [level] = levels || [];
  const [marker] = level.markers || [];
  const [activeLocation, setActiveLocation] = useState(marker);

  const isMobileScreen = width < 1024;

  const onMarkerClick = selectedMarker => {
    if (markersList.current) {
      const { childNodes } = markersList.current;
      const index = level.markers.findIndex(
        marker => marker.id === selectedMarker.id,
      );
      const element = childNodes[index];

      scroll.scrollTo(element.offsetTop, {
        duration: 1200,
        delay: 100,
        smooth: 'easeOutQuint',
        containerId: 'ta-markers-list',
      });
    }

    setActiveLocation(selectedMarker);
  };

  useEffect(() => {
    // Reset selected marker

    setActiveLocation({
      icon: false,
      name: '',
      colour: '',
      id: '',
      coordinates: [
        {
          x: '0',
          y: '0',
        },
      ],
    });

    if (level.markers) {
      setTimeout(() => setActiveLocation(level.markers[0]), 10);
    }
  }, [page]); // eslint-disable-line

  if (!page || !activeLocation) return null;

  return (
    <section className={classnames(styles.masterplan, 'wrap__hundred')}>
      <ScrollToTop />
      <Helmet>
        <title itemProp="name" lang="en">
          St Yves | Masterplan
        </title>
      </Helmet>
      <div className="wrap__max">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.container)}>
            {isMobileScreen ? (
              <h3 className={classnames(styles.title)}>{masterplanTitle}</h3>
            ) : null}

            <Map
              className={styles.map}
              levels={levels}
              activeMarker={activeLocation}
              onMarkerClick={onMarkerClick}
              zoom={false}
              bgcolor="#F2F2F2"
              download={mapDownload || null}
            />

            <div className={styles.mapnav}>
              <div className={styles.content}>
                {!isMobileScreen ? (
                  <>
                    <h3 className={classnames(styles.title)}>
                      {masterplanTitle}
                    </h3>
                  </>
                ) : null}

                <div className={styles.markerslist}>
                  {isMobileScreen ? (
                    <Picker
                      selectedValue={activeLocation.id}
                      onValueChange={value => {
                        const amenity = level.markers.find(
                          amenity => amenity.id === value,
                        );
                        if (amenity) setActiveLocation(amenity);
                      }}
                    >
                      {level.markers.map((amenity, i) => (
                        <Picker.Item key={amenity.id} value={amenity.id}>
                          {amenity.name}
                        </Picker.Item>
                      ))}
                    </Picker>
                  ) : (
                    <ul ref={markersList} id="ta-markers-list">
                      {level.markers.map((amenity, i) => {
                        const { name } = amenity;
                        const active = activeLocation.id === amenity.id;

                        return (
                          <li
                            key={`amenity-${i}`}
                            className={classnames(
                              { [styles.active]: active },
                              styles.listitem,
                            )}
                            onClick={() => setActiveLocation(amenity)}
                          >
                            <button>
                              <span
                                className={classnames(styles.listitem_number)}
                              >
                                {i < 9 && '0'}
                                {i + 1}
                              </span>
                              <span
                                className={classnames(
                                  { active },
                                  styles.listitem_name,
                                )}
                              >
                                {name}
                              </span>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withApollo(Masterplan);
