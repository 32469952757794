import React from "react";
import classnames from "classnames";
import styles from "./imagecta.module.scss";
import Button from "components/Button";

export const Imagecta = ({ ...props }) => {
  const { title, subtitle, buttonLabel, buttonLink, image } = props;
  // console.log(image);
  return (
    <section className={classnames(styles.imageCta)}>
      <div className={classnames(styles.container, "wrap__xxl")}>
        <div className={classnames(styles.content)}>
          <h2 className="headings-font-display-2">{title}</h2>
          <p className={classnames(styles.subtitle, "font-base")}>{subtitle}</p>
          <Button
            color="white"
            text={buttonLabel}
            type="external"
            link={buttonLink}
            size="med"
            className={classnames(styles.button)}
          />
        </div>
        <img
          alt=""
          src={image.mediaItemUrl}
          className={classnames(styles.image)}
        />
      </div>
    </section>
  );
};

export default Imagecta;
