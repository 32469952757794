import React from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';

import ImageFadeIn from 'components/Image';
import Carousel from 'components/Carousel';
import Tag from 'components/Tag';
import { TagRoseworthy } from 'components/Tag';

import styles from './Gallery.module.scss';

// import imageOne from 'assets/images/content/gallery-one.jpg';
// import imageTwo from 'assets/images/content/gallery-two.jpg';
// import imageThree from 'assets/images/content/gallery-three.jpg';
// import imageFour from 'assets/images/content/gallery-four.jpg';
// import imageFive from 'assets/images/content/gallery-five.jpg';
// import imageSix from 'assets/images/content/gallery-six.jpg';
// import imageSeven from 'assets/images/content/gallery-seven.jpg';
// import imageEight from 'assets/images/content/gallery-eight.jpg';

export const Gallery = ({ galleryImages, points, galleryQuote }) => {
  const [width] = useWindowSize();
  const breakPoint = 768;

  const one = galleryImages.one && galleryImages.one.mediaItemUrl;
  const two = galleryImages.two && galleryImages.two.mediaItemUrl;
  const three = galleryImages.three && galleryImages.three.mediaItemUrl;
  const four = galleryImages.four && galleryImages.four.mediaItemUrl;
  const five = galleryImages.five && galleryImages.five.mediaItemUrl;
  const six = galleryImages.six && galleryImages.six.mediaItemUrl;
  const seven = galleryImages.seven && galleryImages.seven.mediaItemUrl;
  const eight = galleryImages.eight && galleryImages.eight.mediaItemUrl;

  const slides = [
    [points.one, points.two, points.three, points.four, points.five],
    [one, two, three, four, five, six, seven, eight],
  ];

  const mobile = (
    <div className={classnames(styles.mobile)}>
      <div className={styles.carousel}>
        <Carousel type="gallery-mobile" slides={slides} />
      </div>

      <div className={styles.quote}>
        <h3 className={'headings-font-display-3'}>
          A state of the art residential community that has a fascinating local
          history built into its fabric.
        </h3>
      </div>
    </div>
  );
  const desk = (
    <div className={styles.desktop}>
      <div className={classnames(styles.imageOneDesk)}>
        <div className={styles.inner}>
          <ImageFadeIn url={one} />
        </div>
      </div>
      <div className={classnames(styles.textBlock, styles.textOne)}>
        <h5>01</h5>
        <h4 className={'font-gallery'}>{slides[0][0]}</h4>
      </div>

      <div className={styles.colThree}>
        <div className={classnames(styles.imageTwoDesk)}>
          <div className={styles.inner}>
            <ImageFadeIn url={two} />
          </div>
        </div>
        <div className={classnames(styles.textBlock, styles.textTwo)}>
          <h5>02</h5>
          <h4 className={'font-gallery'}>{slides[0][1]}</h4>
        </div>
      </div>
      <div className={classnames(styles.imageThreeDesk)}>
        <div className={styles.inner}>
          <ImageFadeIn url={three} />
        </div>
      </div>
      <div className={classnames(styles.imageFourDesk)}>
        <div className={styles.inner}>
          <ImageFadeIn url={four} />
        </div>
      </div>
      <div className={styles.colSix}>
        <div className={classnames(styles.imageFiveDesk)}>
          <div className={styles.inner}>
            <ImageFadeIn url={five} />
          </div>
        </div>
        <div className={classnames(styles.textBlock, styles.textThree)}>
          <h5>03</h5>
          <h4 className={'font-gallery'}>{slides[0][2]}</h4>
        </div>
      </div>
      <div className={classnames(styles.imageSixDesk)}>
        <div className={styles.inner}>
          <ImageFadeIn url={six} />
        </div>
      </div>
      <div className={styles.colEight}>
        <div className={classnames(styles.imageSevenDesk)}>
          <div className={styles.inner}>
            <ImageFadeIn url={seven} />
          </div>
        </div>
        <div className={classnames(styles.textBlock, styles.textFour)}>
          <h5>04</h5>
          <h4 className={'font-gallery'}>{slides[0][3]}</h4>
        </div>
      </div>
      <div className={styles.colNine}>
        <div className={classnames(styles.imageEightDesk)}>
          <div className={styles.inner}>
            <ImageFadeIn url={eight} />
          </div>
        </div>
        <div className={classnames(styles.textBlock, styles.textFive)}>
          <h5>05</h5>
          <h4 className={'font-gallery'}>{slides[0][4]}</h4>
        </div>
      </div>
      <div className={styles.quote}>
        <h3 className={'headings-font-display-3'}>{galleryQuote}</h3>
      </div>
    </div>
  );

  if (!galleryImages) return null;
  return (
    <section className={classnames(styles.gallery, 'wrap__max')}>
      <div className={classnames(styles.innerMain, 'wrap__hundred')}>
        <div className={styles.tagRoseworthy}>
          <TagRoseworthy />
        </div>
        <div className={styles.tag}>
          <Tag />
        </div>

        <div className={classnames(styles.content, 'wrap__variant--seven')}>
          {width < breakPoint ? mobile : desk}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
