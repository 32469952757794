import React from "react";
import { Switch, Route } from "react-router-dom";

import "./property-app.config";

import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";

import ScrollToTop from "utils/ScrollToTop";
import { Theme } from "theme/theme";
// import { PropertyAppProvider } from 'react-property-app';
import Newsandevents from "./pages/newsandevents";
import Contact from "./pages/contact";
import Downloads from "./pages/downloads";
import Masterplan from "./pages/masterplan";
import Amenities from "./pages/amenities";
import Search from "./components/Search";
import DisclaimerAndPrivacyPolicy from "./pages/disclaimerAndPrivacyPolicy";
import Page from "./pages/page";
import Post from "./post/Post";
import Category from "./components/Category";
import mainLayout from "./layouts/mainlayout";
import CovidAlert from "components/CovidAlert/CovidAlert";
//
// Property App
//
import HouseAndLand from "./pages/HouseAndLand";
import LandForSale from "./pages/LandForSale";
import { useScript } from "utils/hooks/useScript";

// scroll

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

const App = (client) => {
  useScript("https://leads.media-tools.realestate.com.au/conversions.js");
  return (
    <Theme>
      <CovidAlert />
      <div className="center">
        <ScrollToTop />
        <Switch>
          <Route
            path="/land-for-sale"
            component={mainLayout(LandForSale)}
            exact
          />
          <Route path="/land-for-sale/*" component={mainLayout(LandForSale)} />
          <Route
            path="/land-for-sale-results"
            component={mainLayout(LandForSale)}
          />
          <Route
            path="/land-for-sale-results/*"
            component={mainLayout(LandForSale)}
          />
          <Route
            path="/house-and-land"
            component={mainLayout(HouseAndLand)}
            exact
          />
          <Route
            path="/house-and-land/*"
            component={mainLayout(HouseAndLand)}
          />
          <Route exact path="/search" component={Search} />
          <Route
            exact
            path="/news-and-events"
            component={mainLayout(Newsandevents)}
          />
          <Route
            exact
            path="/news-and-events/:slug"
            component={mainLayout(Post)}
          />
          <Route exact path="/category/:slug" component={Category} />
          <Route exact path="/contact" component={mainLayout(Contact)} />
          <Route exact path="/downloads" component={mainLayout(Downloads)} />
          <Route exact path="/masterplan" component={mainLayout(Masterplan)} />
          <Route exact path="/amenities" component={mainLayout(Amenities)} />
          <Route exact path="/location" component={mainLayout(Amenities)} />
          <Route
            exact
            path="/disclaimer-and-privacy-policy"
            component={mainLayout(DisclaimerAndPrivacyPolicy)}
          />
          <Route exact path="*" component={Page} />
        </Switch>
      </div>
    </Theme>
  );
};

export default App;
