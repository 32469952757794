import React from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import Button from 'components/Button';
import ImageFadeIn from 'components/Image';
import Tag from 'components/Tag';

import styles from './Education.module.scss';

import { stripBaseUrl } from 'utils/stripBaseUrl';

export const Education = ({
  imageOne,
  imageTwo,
  featureText,
  heading,
  subHeading,
  blockquote,
  buttonOne,
  buttonTwo,
  logo,
  copy,
  ...props
}) => {
  
  const [width] = useWindowSize();
  const breakPoint = 768;

  return (
    <section className={classnames(styles.education, 'wrap__max')}>
      <div className={styles.tagTop}>
        <Tag />
      </div>
      <div className={classnames(styles.inner, 'wrap__variant--five')}>
        <div className={styles.top}>
          <div className={styles.contentDescription}>
            <h2 className={'font-content-description'}>{heading}</h2>
          </div>
          <div className={styles.subHeading}>
            <h3
              className={'headings-font-display-8'}
              dangerouslySetInnerHTML={{ __html: subHeading }}
            ></h3>
          </div>
          <div className={styles.logo}>
            <img src={logo.mediaItemUrl} alt="" />
          </div>
        </div>
        <div className={styles.imageOne}>
          <div className={styles.inner}>
            {width < breakPoint ? (
              <ImageFadeIn url={imageOne.mobile.mediaItemUrl} />
            ) : (
              <ImageFadeIn
                url={imageOne.desktop.mediaItemUrl}
                backgroundPosition={'center'}
              />
            )}
            <div className={styles.tag}>
              <Tag />
            </div>
            {imageOne.text ? (
              <h4 className={'font-copyright'}>{imageOne.text}</h4>
            ) : null}
          </div>
        </div>
        <div className={styles.imageTwo}>
          <div className={styles.inner}>
            {width < breakPoint ? (
              <ImageFadeIn
                url={imageTwo.mobile.mediaItemUrl}
                backgroundPosition={'center'}
              />
            ) : (
              <ImageFadeIn
                url={imageTwo.desktop.mediaItemUrl}
                backgroundPosition={'center'}
              />
            )}
          </div>
          {imageTwo.text ? (
            <h4 className={'font-copyright'}>{imageTwo.text}</h4>
          ) : null}
        </div>
        <div className={styles.featureText}>
          <h2
            className={'headings-font-display-1__variant--one'}
            dangerouslySetInnerHTML={{ __html: featureText }}
          ></h2>
        </div>
        <div className={classnames(styles.copy, 'font-base__variant--one')}>
          <p dangerouslySetInnerHTML={{ __html: copy }}></p>

          <div className={styles.buttons}>
            {buttonOne ? (
              <div className={styles.button}>
                <Button
                  type={buttonOne.type}
                  color={buttonOne.colour}
                  text={buttonOne.label}
                  link={stripBaseUrl(buttonOne.link.url)}
                  size={'med'}
                  fullWidth
                />
              </div>
            ) : null}
            {buttonTwo ? (
              <div className={styles.button}>
                <Button
                  type={buttonTwo.type}
                  color={buttonTwo.colour}
                  text={buttonTwo.label}
                  link={stripBaseUrl(buttonTwo.link.url)}
                  size={'med'}
                  fullWidth
                />
              </div>
            ) : null}
          </div>
        </div>
        {blockquote ? (
          <div className={styles.blockquote}>
            <p className={'font-blockquote'}>“{blockquote}”</p>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Education;
