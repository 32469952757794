import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import useWindowScroll from "@react-hook/window-scroll";
// import { useScrollDirection } from 'react-hooks-lab';
// import { TimelineMax } from 'gsap';
import Button from "../Button";
import Close from "../Close";
import classnames from "classnames";
import { ThemeContext } from "../../theme/theme";

import Icon from "../Icon";
import Modal from "../Modal";

import { stripBaseUrl } from "utils/stripBaseUrl";

import styles from "./Navigation.module.scss";

const burgerItemsNotShownDesktop = ["Discover St Yves", "Masterplan", "Land for Sale - Now Selling", "Amenities", "House and Land - Now Selling"]

export const Navigation = React.memo(({ headerMenu, mobileMenu, minimal }) => {
  const [isOpen, setOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  // const [xTimeline] = useState(new TimelineMax({ paused: true }));
  const {
    transparentHeader,
    closeButton,
    setCloseButtonTrue,
    setCloseButtonFalse,
    heroHeight
  } = useContext(ThemeContext);

  const scrollY = useWindowScroll(10);
  const scrollTriggerHeight = heroHeight > 0 ? heroHeight : 500;

  const toggle = () => {
    if (isOpen || closeButton) {
      setOpen(false);
      setCloseButtonFalse();
    } else if (!isOpen) {
      setOpen(true);
      setCloseButtonTrue();
    }
  };

  useEffect(() => {
    if (scrollY > scrollTriggerHeight) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [scrollY]); //eslint-disable-line

  if (!headerMenu.length || !mobileMenu.length) return null;

  return (
    <>
      <section
        className={classnames(
          isScrolled ? styles.scrolled : null,
          "wrap__hundred",
          styles.navigation,
          { [styles.white]: transparentHeader === "false" }
        )}
      >
        <span className={styles.whitebg} />
        <div className={classnames("wrap__xxl", styles.inner)}>
          {minimal ? (
            <div className={styles.logoLink}>
              <div className={styles.logo}>
                <Icon name={"logoLandscape"} fill={"primary"} />
              </div>
            </div>
          ) : (
            <Link to="/" className={styles.logoLink}>
              <div className={styles.logo}>
                <Icon name={"logoLandscape"} fill={"primary"} />
              </div>
            </Link>
          )}
          <ul
            className={classnames(styles.links, "font-nav", {
              [styles.minimal]: minimal
            })}
          >
            {!minimal ? (
              headerMenu.map((item, i) => {
                return (
                  <li key={`main-navigation-menu-${i}`}>
                    <Link to={stripBaseUrl(item.url)}>{item.label}</Link>
                    {item.childItems.nodes.length ? (
                      <ul>
                        {item.childItems.nodes.map((child, i) => {
                          const url = stripBaseUrl(child.url);

                          return (
                            <li key={`child-item-${i}`}>
                              {/* {
                              url === '/land-for-sale'
                                ? <a href={url}>{child.label}</a>
                                : <Link to={url}> {child.label} </Link>
                            } */}
                              <Link to={url}> {child.label} </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                );
              })
            ) : (
              <>
                <li>
                  Contact us on <a href="tel:1800789837">1800 789 837</a> or
                  <br />
                  via email{" "}
                  <a href="mailto:sales@styves.com.au">sales@styves.com.au</a>
                </li>
                <li>
                  Visit our Sales Centre
                  <br />
                  <a
                    href="https://goo.gl/maps/pSN6EUYNyuWW6vtx7"
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    View Map
                  </a>
                </li>
              </>
            )}
          </ul>
          {!minimal ? (
            <div className={styles.register}>
              <Button
                color="primary"
                text="Register"
                type="register"
                link="register"
                size="small"
              />
            </div>
          ) : null}
        </div>
      </section>
      {!minimal || closeButton ? (
        <div
          className={classnames("wrap__hundred", styles.hamburger, {
            [styles.white]: transparentHeader === "false"
          })}
        >
          <div className={classnames("wrap__xxl", styles.inner)}>
            <div className={classnames(styles.trigger)} onClick={toggle}>
              <Close isOpen={isOpen} transparentHeader={transparentHeader} />
            </div>
            <div className={styles.modal}>
              <Modal isOpen={isOpen}>
                <div className={classnames("wrap__xxl", styles.child)}>
                  <ul>
                    {mobileMenu.map((item, i) => {
                      return (
                        <li key={`mobile-navigation-menu-${i}`}>
                          <Link
                            className={classnames("font-modal", 
                            {[styles.notShownDesktop] : burgerItemsNotShownDesktop.includes(item.label)},
                            )}
                            to={stripBaseUrl(item.url)}
                            onClick={toggle}
                          >
                            {item.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default Navigation;
