import React, { useState, useEffect, useContext } from "react";
import classnames from "classnames";

import { withApollo } from "react-apollo";
import gql from "graphql-tag";

import { FooterAbout, FooterRegister } from "components/Footer";

import { ThemeContext } from "theme/theme";

import styles from "./Footer.module.scss";

const FOOTER_QUERY = gql`
  query MyQuery {
    themeSettings {
      theme_settings {
        address
        contactEmail
        contactNumber
        fieldGroupName
        openHours
        rlaNumber
        registerFormLeaderText
        social {
          fieldGroupName
          label
          link {
            url
          }
          icon {
            mediaItemUrl
          }
        }
        legal {
          label
          link {
            ... on Page {
              uri
            }
          }
          fieldGroupName
        }
        company {
          fieldGroupName
          name
          link {
            url
          }
          logo {
            mediaItemUrl
          }
        }
        download {
          file {
            url
            target
            title
          }
          fieldGroupName
          label
        }
        googleMapsAddressLink {
          url
        }
        copyrightLabel
      }
    }
  }
`;

export const Footer = ({ minimal, pageSlug, ...props }) => {
  const [state, setState] = useState({
    footerInfo: []
  });

  const { setRegisterFormLeaderText } = useContext(ThemeContext);

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executeMenu = async () => {
      const { client } = props;
      const footerResult = await client.query({
        query: FOOTER_QUERY
      });
      if (!requestCancelled) {
        const footerInfo = footerResult.data.themeSettings.theme_settings;
        setState({ footerInfo });
      }
    };

    executeMenu();

    return () => {
      requestCancelled = true;
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    setRegisterFormLeaderText(footerInfo.registerFormLeaderText);
  }, [state]); //eslint-disable-line

  const { footerInfo } = state;
  const footerAboutData = {
    address: footerInfo.address,
    contactEmail: footerInfo.contactEmail,
    contactNumber: footerInfo.contactNumber,
    openHours: footerInfo.openHours,
    rlaNumber: footerInfo.rlaNumber,
    download: !minimal && footerInfo.download,
    company: footerInfo.company,
    googleMapsAddressLink: footerInfo.googleMapsAddressLink,
    social: footerInfo.social
  };
  return (
    <footer className={classnames("wrap__hundred", styles.footer)}>
      <FooterAbout
        // address={footerInfo.address}
        // contactEmail={footerInfo.contactEmail}
        // contactNumber={footerInfo.contactNumber}
        // openHours={footerInfo.openHours}
        // rlaNumber={footerInfo.rlaNumber}
        // download={!minimal && footerInfo.download}
        // company={footerInfo.company}
        // googleMapsAddressLink={footerInfo.googleMapsAddressLink}
        // social={footerInfo.social}
        data={footerAboutData}
        pageSlug={pageSlug}
      />
      <FooterRegister
        company={!minimal && footerInfo.company}
        legals={footerInfo.legal}
        copyright={footerInfo.copyrightLabel}
        pageSlug={pageSlug}
      />
    </footer>
  );
};

export default withApollo(Footer);
