import React from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import Button from 'components/Button';

import ImageFadeIn from 'components/Image';
import Carousel from 'components/Carousel';

import styles from './Fullimagewithlink.module.scss';

export const Fullimagewithlink = ({images}) => {
  const breakPoint = 768;
  const [width] = useWindowSize();
  

  const slider = images.length >= 2 ? true : false;

  const params = {
    autoplay: { delay: 4500 },
    effect: 'fade',
    speed: 1500,
    loop: true,
    containerClass: 'feature-swiper',
    navigation: {
      nextEl: '.feature-swiper-button-next',
      prevEl: '.feature-swiper-button-prev',
    },
    slidesPerView: 1,
    freeModeMomentum: true,
    renderPrevButton: () => (
      <div className='feature-swiper-button-prev'>Prev</div>
    ),
    renderNextButton: () => (
      <div className='feature-swiper-button-next'>Next</div>
    ),
  };

  return (
    <section className={classnames(styles.fullImageWithLink, 'wrap__max')}>
      <div className={classnames(styles.content, 'wrap__xxl')}>
        <h2 className={'headings-font-display-3'}>
          An environment shaped<br /> to your advantage.
        </h2>
        <div className={styles.button}>
          <Button
            color='primary'
            type='internal'
            text='Discover St Yves'
            link={'/discover-st-yves'}
            size='med'
          />
        </div>
      </div>

      {slider ? (
        <div className={styles.slider}>
          <Carousel type='feature' params={params} slides={images} />
        </div>
      ) : (
        <div className={styles.image}>
          {width < breakPoint ? (
            <ImageFadeIn url={images[0].mobile.mediaItemUrl} />
          ) : (
            <ImageFadeIn url={images[0].desktop.mediaItemUrl} />
          )}
        </div>
      )}
    </section>
  );
};

export default Fullimagewithlink;
