import React, { useState, useEffect, useRef } from 'react';
import { TimelineMax } from 'gsap';
import useWindowSize from '@react-hook/window-size';
// import {
//   enableBodyScroll,
//   disableBodyScroll,
//   clearAllBodyScrollLocks,
// } from 'body-scroll-lock';


// import pattern from 'assets/images/content/pattern.png';

import styles from './Modal.module.scss';

export const Modal = ({ isOpen, children }) => {
  const [modalTimeline] = useState(new TimelineMax({ paused: true }));
  const [width, height] = useWindowSize();
  const modal = useRef();
  const child = useRef();
  const [modalSize, setModalSize] = useState();
  // const targetElement = useRef();

  useEffect(() => {
    width > height ? setModalSize('300vw') : setModalSize('300vh');
  }, [height, width]);

  useEffect(() => {
    modalTimeline.to(
      modal.current,
      1,
      {
        width: modalSize,
        height: modalSize,
      },
      0,
    );

    modalTimeline.to(
      child.current,
      0,
      {
        display: 'flex',
      },
      1,
    );
    modalTimeline.to(
      child.current,
      0.5,
      {
        opacity: 1,
      },
      1,
    );
  }, [modalTimeline, modalSize]);

  useEffect(() => {
    if (isOpen) {
      modalTimeline.play();
      // disableBodyScroll(child);
      // console.log('disable scroll')
    } else {
      modalTimeline.reverse();
      // enableBodyScroll(child);
      // console.log('enable scroll')
    }
  }, [isOpen, modalTimeline]);

  useEffect(() => {
    return () => {
      // clearAllBodyScrollLocks();
      modalTimeline.reverse();
    };
  }, []); // eslint-disable-line
  return (
    <>
      <div ref={modal} className={styles.modal}></div>
      <div ref={child} className={styles.child} >
        {children}
      </div>
    </>
  );
};

export default Modal;
