import { useState, useEffect } from "react";

const stores = {};

const isLocalStorageExpired = name => {
  const today = new Date();
  const showAt = localStorage.getItem(name);
  const store = stores[name];
  const hasDateChanged = store && store.showAt !== showAt;

  if (!showAt) return true;

  if (hasDateChanged || store === undefined) {
    const expired = !showAt || today.getTime() >= showAt;
    stores[name] = { showAt, expired };
  }

  return stores[name].expired;
};

const parseWhen = when => {
  const nextWeek = () => {
    const today = new Date();
    const nextDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    return nextDate;
  };

  const tomorrow = () => {
    const today = new Date();
    const nextDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return nextDay;
  };

  if (when === "tomorrow") return tomorrow();
  if (when === "next-week") return nextWeek();
  return nextWeek();
};

export const useStateOnceUntil = (when = "tomorrow", key, defaultState) => {
  const [state, forceSetState] = useState(defaultState);
  const expired = isLocalStorageExpired(key);

  useEffect(() => {
    if (state === true) {
      const after = new Date(parseWhen(when));
      localStorage.setItem(key, after.getTime());
    }
  }, [state, key, when]);

  const setState = value => {
    if (expired || value === false) forceSetState(value);
  };

  return [state, setState, forceSetState];
};

export default useStateOnceUntil;
