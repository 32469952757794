import React, {useState, useEffect} from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import useWindowScroll from "@react-hook/window-scroll";
import classnames from "classnames";
import styles from './CovidAlert.module.scss';

const COVID_ALERT_QUERY = gql`
    query MyQuery {
        themeSettings {
        theme_settings {
            text
            enableAlert
        }
        }
    }
`;
const CovidAlert = (props) => {
    const [showAlert, setShowAlert] = useState(true);
    const [data, setData] = useState({});
    const [isScrolled, setScrolled] = useState(false);
    const handleAlert = () => {
        setShowAlert(!showAlert);
    }
    const scrollY = useWindowScroll(10);
    useEffect(() => {
        if (scrollY > 200 && window.innerWidth <= 1024) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
    }, [scrollY]); //eslint-disable-line
    useEffect(() => {
        const executeQuery = async () => {
            const { client } = props;
            const res = await client.query({
                query: COVID_ALERT_QUERY
            });
            const result = res.data.themeSettings.theme_settings
            setData(result)
        };  
        executeQuery();
    }, [props])
    return (
        <>
        {showAlert && data.enableAlert && (
        <div 
        className={classnames(
            isScrolled ? styles.scrolled : null,
            styles.covidAlert
          )}
        >
            <p>{data.text}</p>
            <div onClick={() => handleAlert()} className={styles.close}>X</div>
        </div>
        )}
        </>
    )
}

export default withApollo(CovidAlert);
