import React, { useState, useRef } from "react";
import classnames from "classnames";
import styles from "./Mapblock.module.scss";
import carIcon from "assets/images/content/click-to-pan.svg";
import close from "assets/images/content/close.svg";

export const Mapblock = ({ desktopMap, mobileMap }) => {
  const targetElement = useRef();
  const [showPan, setShowPan] = useState(false);
  const handlePanClick = (value: boolean) => {
    setShowPan(value);
    scrollingTop();
  };

  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scroll({
      top: 100,
      left: 800,
      behavior: "smooth"
    });
  };
  return (
    <div className={styles.mapWrapper}>
      {desktopMap && (
        <div className={styles.desktopMap}>
          <img alt="desktopMap" src={desktopMap.mediaItemUrl} />
        </div>
      )}
      {mobileMap && (
        <div className={styles.mobileMap}>
          <img alt="desktopMap" src={mobileMap.mediaItemUrl} />
        </div>
      )}

      <div
        className={classnames(
          styles.panImageWrapper,
          showPan && desktopMap ? styles.open : styles.close
        )}
      >
        <div className={styles.panImage} ref={targetElement}>
          <img src={desktopMap.mediaItemUrl} alt="" />
        </div>
        <div className={styles.closeBttn} onClick={() => handlePanClick(false)}>
          <span>Close</span>
          <img alt="desktopMap" src={close} />
        </div>
      </div>

      <div className={styles.panIconWrapper}>
        <div className={styles.panIcon}>
          <img
            onClick={() => handlePanClick(true)}
            src={carIcon}
            alt="pan-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Mapblock;
